<template>
  <div v-if="documentValid">
    <document-header v-if="metaReady" :searchCache="searchCache"></document-header>
    <document-meta v-if="metaReady" :document="documentMeta"></document-meta>
    <div class="d-flex justify-content-end mt-4">
      <document-versions
        v-if="metaReady"
        :versionOptions="versionOptions"
        :selectedVersion="version"
        @versionChange="versionChange"
      ></document-versions>
      <btn-doc-download v-if="metaReady" v-model="documentMeta.guid"></btn-doc-download>
    </div>
    <div v-if="metaReady">
      <document-body
        v-if="documentHtml != '' && !isPdf"
        :versionReady="htmlReady"
        :document="documentHtml"
        :isWindowBig="isWindowBig"
        :fileGuid="documentMeta.guid"
      ></document-body>
      <document-pdf-body
        v-else-if="isPdf"
        :fileGuid="documentMeta.guid"
        :showInWeb="documentMeta.showInWeb"
      ></document-pdf-body>
    </div>
    <document-appendices
      v-if="metaReady && appendicesValid"
      refType="appendix"
      :refs="documentMeta.appendices"
      @reloadDocumentEvent="reloadDocument"
    ></document-appendices>
    <div
      v-if="metaReady && showScrollButton"
      class="ln-scroll-top btn-primary ln-pointer"
      @click="scrollToTop"
    >
      <font-awesome-icon
        :icon="['fas', 'angle-up']"
        size="lg"
        class="ln-scroll-top-icon"
      />
    </div>
  </div>
  <div v-else class="text-danger">Neteisingi užklausos duomenys</div>
</template>

<script>
import {
  GetDocumentHtml,
  GetDocumentMeta,
  GetDocumentIdBySid,
  GetDocumentIdByNr,
  GetDocumentIdByProjNr,
} from "../Assets/repository";
import DocumentHeader from "./Components/document-header";
import DocumentMeta from "./Components/document-meta";
import DocumentVersions from "./Components/document-versions";
import DocumentBody from "./Components/document-body";
import DocumentPdfBody from "./Components/document-pdf-body";
import DocumentAppendices from "./Components/document-appendices";
import BtnDocDownload from "../Shared/Components/btn-doc-download";

export default {
  name: "document-page",
  components: {
    DocumentMeta,
    DocumentHeader,
    DocumentVersions,
    DocumentBody,
    DocumentAppendices,
    DocumentPdfBody,
    BtnDocDownload,
  },
  props: {},
  created() {
    this.searchCache.documentList = this.$route.params.searchdocumentList;
    this.searchCache.currentPage = this.$route.params.searchCurrentPage;
    this.searchCache.resultPerPage = this.$route.params.searchResultPerPage;

    if (this.$route.query.sid != undefined) {
      GetDocumentIdBySid(this.$route.query.sid).then((res) => {
        this.docId = res.data;
        this.loadDocument();
        this.loadMeta();
      });
    } else if (this.$route.query.regnr != undefined) {
      console.log("get by regnr" + this.$route.query.regnr);
      GetDocumentIdByNr(
        this.$route.query.regnr,
        this.$route.query.date,
        this.$route.query.DocRusys
      ).then((res) => {
        this.docId = res.data;
        this.loadDocument();
        this.loadMeta();
      });
    } else if (
      this.$route.query.projnr != undefined &&
      this.$route.query.date != undefined
    ) {
      GetDocumentIdByProjNr(this.$route.query.projnr, this.$route.query.date).then(
        (res) => {
          this.docId = res.data;
          this.loadDocument();
          this.loadMeta();
        }
      );
    } else {
      this.docId = this.$route.params.id;

      this.loadMeta();
    }
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("scroll", this.handleScrollButtonVisibility);
    this.handleResize();
  },
  updated() {
    this.bugSav105ResolverWorkAround();
  },
  methods: {
    bugSav105ResolverWorkAround() {
      if (this.documentHtml !== undefined) {
        let MsoHeader = document.getElementsByClassName("MsoHeader");
        let MsoNormal = document.getElementsByClassName("MsoNormal");
        if (MsoNormal !== undefined) {
          let targetIII = MsoNormal[1];
          let targetII = MsoNormal[0];
          if (targetIII != null) {
            targetIII.outerHTML = targetIII.outerHTML.replace(
              "v:imagedata",
              "img width=52"
            );
          }
          if (targetII) {
            targetII.outerHTML = targetII.outerHTML.replace(
              "v:imagedata",
              "img width=52"
            );
          }
        }
        if (MsoHeader[0] !== undefined) {
          let target = MsoHeader[0];
          if (target != null) {
            target.outerHTML = target.outerHTML.replace("v:imagedata", "img width=52");
          }
        }
      }
    },
    reloadDocument(id, version = 0) {
      this.docId = id;
      this.version = version;
      this.loadDocument();
      this.loadMeta();
    },
    loadDocument() {
      let self = this;
      this.htmlReady = false;

      GetDocumentHtml(this.docId, this.version)
        .then(function (res) {
          self.documentHtml = res.data;
          self.htmlReady = true;
        })
        .catch(() => {
          //this.$router.push('404');
          window.location.replace("/404");
        });
    },

    loadMeta() {
      let _this = this;
      this.metaReady = false;
      GetDocumentMeta(this.docId, this.version)
        .then(function (res) {
          _this.documentMeta = res.data;
          if (_this.documentMeta == "") window.location.replace("/404");
          else {
            _this.metaReady = true;
            _this.version = res.data.version;
            if (_this.documentMeta.fileType === "pdf") {
              console.log("PDF document");
            }
            _this.loadDocument();
            document.title = `${_this.documentMeta.regNr} ${_this.documentMeta.title}`;
          }
        })
        .catch(() => {
          //this.$router.push('404');
          window.location.replace("/404");
        });
    },

    versionChange(version) {
      this.version = version;
      this.htmlReady = false;
      this.metaReady = false;
      this.loadDocument();
      this.loadMeta();
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    scrollToTop() {
      document
        .getElementById("ln-district-blazon")
        .scrollIntoView({ behavior: "smooth" });
    },
    handleScrollButtonVisibility() {
      if (window.pageYOffset > 50) {
        this.showScrollButton = true;
      } else {
        this.showScrollButton = false;
      }
    },
  },
  computed: {
    versionOptions() {
      let options = [];
      if (this.documentMeta.versions != null) {
        this.documentMeta.versions.forEach((o) => {
          let text = "";
          var dateText = "";
          if (o.isActual) text = "Aktuali redakcija";
          if (o.isFutureActual) text = "Įsigalios";
          if (o.date == null) {
            dateText = { value: o.number, text: `${o.number} ${text}` };
          } else {
            dateText = { value: o.number, text: `${o.number} (${o.date}) ${text}` };
          }

          options.push(dateText);
        });
      }
      return options;
    },
    documentValid() {
      if (!this.htmlReady || !this.metaReady) return true;
      else if (this.documentHtml == null || this.documentMeta == null) return false;
      else return this.docId == this.documentHtml.id;
    },
    appendicesValid() {
      if (this.documentMeta.appendices) return true;
      else return false;
    },
    isWindowBig() {
      return this.windowWidth > 900;
    },
    isPdf() {
      return this.documentMeta.fileType === "pdf";
    },
  },
  data() {
    return {
      documentHtml: undefined,
      documentMeta: undefined,
      docId: undefined,
      htmlReady: false,
      metaReady: false,
      version: 0,
      windowWidth: 0,
      showScrollButton: false,
      searchCache: {
        documentList: undefined,
        currentPage: 0,
        resultPerPage: 10,
      },
    };
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div>
    <b-form @submit="onSubmit">
      <b-form-group
        label="Prisijungimo vardas"
        label-for="username"
        label-class="ln-text-primary font-weight-bold"
      >
        <b-form-input
          id="username"
          v-model="form.usr"
          type="text"
          autocomplete="username"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label="Slaptažodis"
        label-for="pwd"
        label-class="ln-text-primary font-weight-bold"
      >
        <b-form-input
          id="pwd"
          v-model="form.pwd"
          type="password"
          autocomplete="current-password"
        ></b-form-input>
      </b-form-group>

      <b-button type="submit" class="ln-bnt-primary">
        <span v-if="ready">Prisijungti</span>
        <span v-if="!ready">Palaukite..</span>
        <b-spinner v-if="!ready" small></b-spinner>
      </b-button>

      <div v-if="failed" class="text-danger">Atleiskite, prisijungti nepavyko.</div>
    </b-form>
    <div class="text-right">
      <!-- <a href="javascript:void(0);"  @click="remember()">Pamiršote?</a> -->
      <!-- <a href="javascript:void(0);" v-else>Patikrinkite email</a> -->
    </div>
  </div>
</template>

<script>
import { HttpClient } from "../../http-client";
import { userService } from "../../_Services/user-service";

export default {
  name: "login-form",
  mounted() {
    this.token = localStorage.getItem("token");
    this.tokenExpires = localStorage.getItem("tokenExpires");
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      this.ready = false;
      // if (this.loggedIn) this.logout();
      // else this.login();
      this.login();
    },
    login() {
      localStorage.removeItem("token");
      localStorage.removeItem("tokenExpires");
      HttpClient.post(`account/login`, {
        UserName: this.form.usr,
        Password: this.form.pwd,
      })
        .then((res) => {
          this.ready = true;
          //console.log(res);
          if (res.data.result == "Success") {
            this.failed = false;
            this.token = res.data.token;
            this.tokenExpires = res.data.tokenExpires;
            localStorage.setItem("token", this.token);
            localStorage.setItem("tokenExpires", this.tokenExpires);

            const today = new Date();
            const passwordExpires = new Date(userService.tokenData().PasswordExpires);
            this.passwordExpired = passwordExpires != null && passwordExpires < today;

            localStorage.setItem("passwordExpired", this.passwordExpired);

            if (!this.passwordExpired) {
              setTimeout(function () {
                location.href = `${process.env.VUE_APP_BASE_URL}`;
              }, 100);
            } else {
              setTimeout(function () {
                location.href = `${process.env.VUE_APP_BASE_URL}logininternaluser`;
              }, 100);
            }
          } else {
            this.failed = true;
          }
        })
        .catch((ex) => {
          console.error(ex);
          this.ready = true;
        });
    },
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("tokenExpires");
      localStorage.removeItem("passwordExpired");
      this.token = null;
      this.tokenExpires = null;
      this.ready = true;
      setTimeout(function () {
        location.href = `${process.env.VUE_APP_BASE_URL}`;
      }, 1000);
    },
    remember() {
      this.$emit("remember");
    },
  },
  computed: {
    loggedIn() {
      return this.token != null;
    },
  },
  data() {
    return {
      form: {
        usr: "",
        pwd: "",
      },
      ready: true,
      failed: false,
      token: null,
      tokenExpires: null,
      passwordExpired: false,
      isRemember:false,
    };
  },
};
</script>

<style lang="scss" scoped></style>

import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import { HttpClient } from './http-client.js';

export const store = new Vuex.Store({
   state: {
      districtConfig: {
         title: undefined,
         blazon: undefined,
         ready: false,
      },
      searchCache: {
         currentPage: 1,
         resultPerPage: 10,
         queryParams: undefined,
         value: undefined,
      },
      searchCacheReady: false,
   },
   getters: {
      districtConfig(state) {
         if (state.districtConfig.ready === false) {
            HttpClient.get(`config`)
               .then(res => {
                  state.districtConfig.title = res.data.title;
                  state.districtConfig.blazon = res.data.blazon;
                  state.districtConfig.ready = true;
               })
               .catch(ex => {
                  console.error(ex);
               });
         }
         return state.districtConfig;
      },
      searchParams(state) {
         return state.searchParams;
      },
   },
   mutations: {
      setSearchCache(state, data) {
         state.searchCache = data;
         state.searchCacheReady = true;
         //console.log('Updating search cache');
      },
      clearSearchCache(state) {
         state.searchCache = {
            currentPage: 1,
            resultPerPage: 10,
            queryParams: undefined,
            value: undefined,
         };
         state.searchCacheReady = false;
         //console.log('Clearing search cache');
      },
   },
   actions: {
      setSearchCache({ commit }, data) {
         commit('setSearchCache', data);
      },
      clearSearchCache({ commit }) {
         commit('clearSearchCache');
      },
      getConfig(context) {
         context.commit('getConfig');
      },
   },
});

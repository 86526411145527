<template>
  <div class="container">
    <div class="row mt-lg-5">
      <div class="col-lg-3 col-md-3"></div>
      <div class="col-lg-8 col-md-6 col-sm-12">
        <div v-if="isLoggedIn">
          <password-expired-form
            class="m-2 mb-5"
            v-if="isPasswordExpired"
          ></password-expired-form>
          <div>Jūs esate prisijungę!</div>
          <button
            class="btn ln-bnt-primary text-white m-2"
            type="button"
            @click="logout()"
          >
            Atsijungti
          </button>
          <button class="btn ln-bnt-primary text-white m-2" @click="navigateToSearch">
            Eiti į paiešką
          </button>
          <button
            v-if="hasAdminRole"
            class="btn ln-bnt-primary text-white m-2"
            @click="() => this.$router.push('/manageinternaluser')"
          >
            <i class="fas fa-users"></i>
          </button>
          <button
            v-if="isNewsletterEnabled"
            class="btn ln-bnt-primary text-white m-2"
            @click="() => this.$router.push('/newslettersubscription')">
            Naujienlaiškio prenumerata
          </button>
        </div>
        <div v-else>
          <login-form v-if="loginMode" @remember="remember"></login-form>
          <remember-password-form v-else></remember-password-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from "./Components/login-form";
import RememberPasswordForm from "./Components/remember-password-form";
import PasswordExpiredForm from "./Components/password-expired-form";
import { UserInfo } from "../Assets/repository";
import { userService } from "../_Services/user-service";

export default {
  name: "login-page",
  components: { LoginForm, RememberPasswordForm, PasswordExpiredForm },
  created() {
    this.tokenExpires = localStorage.getItem("tokenExpires");
    if (this.tokenValid) {
      UserInfo()
        .then((res) => {
          this.userValid = res.data.id !== undefined;
        })
        .catch(() => (this.userValid = false));
    }
    
  },
  mounted(){
    this.token = this.$route.query.token;
    if(this.token != undefined){
       userService.sendPasswordRestoreToken(this.token)
       location.href = `${process.env.VUE_APP_BASE_URL}logininternaluser`;
    } 
  },
  computed: {
    isLoggedIn() {
      return this.tokenValid && this.userValid;
    },
    tokenValid() {
      const today = new Date();
      const tokenExpires = new Date(this.tokenExpires);
      return tokenExpires > today;
    },
    hasAdminRole() {
      const tokenData = userService.tokenData();
      return tokenData.Role == 1;
    },
    isPasswordExpired() {
      return Boolean(localStorage.getItem("passwordExpired") == "true");
    },
    isNewsletterEnabled() {
      return process.env.VUE_APP_ENABLE_NEWSLETTER === "true";
    }
  },
  methods: {
    remember() {
      this.loginMode = false;
    },
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("tokenExpires");
      localStorage.removeItem("passwordExpired");
      setTimeout(function () {
        location.href = `${process.env.VUE_APP_BASE_URL}`;
      }, 1000);
    },
    navigateToSearch() {
      this.$router.push("/");
    },
    
  },
  data() {
    return {
      loginMode: true,
      tokenExpires: "",
      userValid: false,
      token: "",
    };
  },
};
</script>

<style lang="css" scoped></style>

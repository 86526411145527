<template>
  <div>
    <h2>Naudotojų sąrašas</h2>
    <b-button @click="newUserClick" size="sm" class="float-right"
      >Pridėti naudotoją</b-button
    >
    <div>
      <b-table
        :busy="!ready"
        striped
        small
        hover
        :items="userList"
        @row-clicked="rowClicked"
        :fields="tableFields"
      >
        <template #cell(active)="data">
          {{ data.value == true ? "Taip" : "Ne" }}
        </template>
      </b-table>
    </div>

    <b-modal ref="user-modal" id="user_modal" title="Naudotojas" size="lg" ok-disabled>
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="Naudotojo vardas"
          label-for="txt_username"
          label-size="sm"
          invalid-feedback="Privalomas laukas"
        >
          <b-form-input
            id="txt_username"
            v-model="user.userName"
            size="sm"
            :state="user.userName.length != 0"
          ></b-form-input>
        </b-form-group>
        <!-- ---------------------------------------- -->
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="Vardas ir pavardė"
          label-for="txt_realName"
          invalid-feedback="Privalomas laukas"
          label-size="sm"
        >
          <b-form-input
            id="txt_realName"
            v-model="user.realName"
            required
            :state="user.realName.length != 0"
            size="sm"
          ></b-form-input>
        </b-form-group>
        <!-- ---------------------------------------- -->
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="El. paštas"
          label-for="c_email"
          invalid-feedback="Privalomas teisingas el. paštas"
          label-size="sm"
        >
          <b-form-input
            id="c_email"
            v-model="user.email"
            type="email"
            size="sm"
            :state="validEmail(user.email)"
          ></b-form-input>
        </b-form-group>
        <!-- ---------------------------------------- -->
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="Aktyvus?"
          label-for="c_active"
          label-size="sm"
        >
          <b-form-checkbox
            id="c_active-1"
            v-model="user.active"
            value="true"
            unchecked-value="false"
            size="sm"
          >
          </b-form-checkbox>
        </b-form-group>
        <!-- ---------------------------------------- -->
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="Rolė"
          label-for="c_roles"
          label-size="sm"
        >
          <b-form-select
            id="c_roles"
            v-model="user.roles"
            :options="rolesOptions"
            size="sm"
            class="m-0"
          ></b-form-select>
        </b-form-group>
        <!-- ---------------------------------------- -->
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="Patvirtinas?"
          label-for="c_isConfirmed"
          label-size="sm"
        >
          <b-form-checkbox
            id="c_isConfirmed"
            v-model="user.c_isConfirmed"
            value="true"
            unchecked-value="false"
          >
          </b-form-checkbox>
        </b-form-group>
        <!-- ---------------------------------------- -->
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="Užrakinamas?"
          label-for="c_lockout_enabled"
          label-size="sm"
        >
          <b-form-checkbox
            id="c_lockout_enabled"
            v-model="user.lockoutEnabled"
            value="true"
            unchecked-value="false"
          >
          </b-form-checkbox>
        </b-form-group>
        <!-- ---------------------------------------- -->
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          content-cols-sm
          content-cols-lg="7"
          label="Slaptažodis baigia galioti"
          label-for="c_password_expires"
          label-size="sm"
        >
          <b-form-datepicker
            id="c_password_expires"
            v-model="user.passwordExpires"
            size="sm"
            :hide-header="true"
            today-button
            reset-button
            locale="lt"
          ></b-form-datepicker>
        </b-form-group>
      </form>
      <template #modal-footer>
        <div class="w-100">
          <b-button :disabled="!formValid" @click="handleSubmit" class="float-right"
            >Įrašyti</b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { HttpClient } from "../http-client";
import { userService } from "../_Services/user-service";

export default {
  created() {
    this.user = Object.assign({}, userService.getDefaultUser());
  },
  mounted() {
    this.getUsers();
  },
  computed: {
    formValid() {
      return (
        this.user.userName.length != 0 &&
        this.validEmail(this.user.email) &&
        this.user.realName.length != 0
      );
    },
  },
  methods: {
    getUsers() {
      HttpClient.get(`account/GetUsers`)
        .then((res) => {
          this.ready = true;
          this.userList = res.data;
        })
        .catch((ex) => {
          console.error(ex);
          this.ready = true;
        });
    },
    rowClicked(event) {
      //console.log(event);
      this.user = Object.assign({}, event);
      this.$refs["user-modal"].show();
    },
    validEmail: function (email) {
      var re = /(.+)@(.+){2,}\.(.+){2,}/;
      return re.test(email.toLowerCase());
    },
    handleSubmit() {
      HttpClient.post(`account/InsertOrUpdateUser`, this.user)
        .then((res) => {
          this.ready = true;
          this.$refs["user-modal"].hide();
          console.log(res);
          this.userList = res.data;
        })
        .catch((ex) => {
          console.error(ex);
          this.ready = true;
        });
    },
    newUserClick() {
      this.user = Object.assign({}, userService.getDefaultUser());
      this.$refs["user-modal"].show();
    },
  },
  data() {
    return {
      ready: false,
      userList: [],
      user: {},
      rolesOptions: [
        { value: "0", text: "Rangovas" },
        { value: "1", text: "Administratorius" },
      ],
      tableFields: [
        { key: "userName", label: "Prisijungimo vardas" },
        { key: "realName", label: "Pilnas vardas" },
        { key: "email", label: "El.paštas" },
        { key: "roles", label: "Rolė" },
        { key: "active", label: "Aktyvus?" },
        { key: "accessFailedCount", label: "Neteis. prisij. kiekis" },
        { key: "lockoutEnd", label: "Užrakinimas baigiasi" },
        { key: "passwordExpires", label: "Slaptažodis baigia galioti" },
      ],
    };
  },
};
</script>

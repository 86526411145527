import { HttpClient } from '../http-client';

export function GetDocumentList(queryParams) {
   var promise = new Promise(function (resolve) {
      HttpClient.get(`search` + queryParams)
         .then((res) => {
            resolve(res);
         })
         .catch((ex) => {
            console.error(ex);
         });
   });
   return promise;
}

export function GetPublicationsList(cardIdsCsv) {
   var promise = new Promise(function (resolve) {
      HttpClient.get(`Publication?cardIdsCsv=` + cardIdsCsv)
         .then(res => {
            resolve(res);
         })
         .catch(ex => {
            console.error(ex);
         });
   });
   return promise;
}

export function GetDocumentHtml(id, version) {
   var promise = new Promise(function (resolve, reject) {
      if (version == undefined) version = 1;
      let uri = `document/${id}?version=${version}`;
      HttpClient.get(uri)
         .then((res) => {
            resolve(res);
         })
         .catch((ex) => {
            console.error(ex);
            reject();
         });
   });
   return promise;
}
export function GetDocumentMeta(id, version) {
   var promise = new Promise(function (resolve, reject) {
      HttpClient.get(`documentmeta/${id}?version=${version}`)
         .then((res) => {
            resolve(res);
         })
         .catch((ex) => {
            console.error(ex);
            reject();
         });
   });
   return promise;
}
export function GetDocumentIdBySid(sid) {
   var promise = new Promise(function (resolve, reject) {
      HttpClient.get(`document?sid=${sid}`)
         .then((res) => {
            resolve(res);
         })
         .catch((ex) => {
            console.error(ex);
            reject();
         });
   });
   return promise;
}
export function GetDocumentIdByNr(nr, date) {
   var promise = new Promise(function (resolve, reject) {
      const url = date === undefined ? `document?nr=${nr}`: `document?nr=${nr}&date=${date}`;
      console.log("requesting document id from ", url);
      HttpClient.get(url)
         .then((res) => {
            console.log("found document id - ",res.data);
            resolve(res);
         })
         .catch((ex) => {
            console.error(ex);
            reject();
         });
   });
   return promise;
}

export function GetDocumentIdByProjNr(projnr, date) {
   var promise = new Promise(function (resolve, reject) {
      HttpClient.get(`document?projnr=${projnr}&date=${date}`)
         .then((res) => {
            resolve(res);
         })
         .catch((ex) => {
            console.error(ex);
            reject();
         });
   });
   return promise;
}

export function UserInfo() {
   let promise = new Promise(function (resolve) {
      HttpClient.get(`account/userinfo`)
         .then((res) => {
            resolve(res);
         })
         .catch((ex) => {
            resolve(ex);
            //            reject();
         });
   });
   return promise;
}

export function GetClassificators() {
   let query = `?include=DocumentTypes,Organizations`;
   if (Boolean(process.env.VUE_APP_SEARCH_RUBRIK) == true) query += ',Rubricators';
   if (Boolean(process.env.VUE_APP_SEARCH_STATUS) == true) query += ',Status';
   if (Boolean(process.env.VUE_APP_SEARCH_PUBLICATION) == true) query += ',Publication';
   if (Boolean(process.env.VUE_APP_SHOW_STAKEHOLDERS) == true) query += ',Stakeholders';

   let promise = new Promise(function (resolve) {
      HttpClient.get(`classificators${query}`)
         .then((res) => {
            resolve(res);
         })
         .catch((ex) => {
            resolve(ex);
         });
   });
   return promise;
}

export function GetDocumentTypesClassificator() {
   let query = `?include=DocumentTypes`;

   let promise = new Promise(function (resolve) {
      HttpClient.get(`classificators${query}`)
         .then((res) => {
            resolve(res);
         })
         .catch((ex) => {
            resolve(ex);
         });
   });
   return promise;
}

export function GetNewsletterSubscriptionRules() {
   let promise = new Promise(function (resolve, reject) {
   let uri = `SubscribeNewsletter`;
   HttpClient.get(uri)
      .then((res) => {
         resolve(res);
      })
      .catch((ex) => {
         console.error(ex);
         reject();
      });
   });
   return promise;
}

export function PostNewsletterSubscriptionRule(rule) {
   let promise = new Promise(function (resolve, reject) {
   let uri = `SubscribeNewsletter`;
   HttpClient.post(uri, rule)
      .then((res) => {
         resolve(res);
      })
      .catch((ex) => {
         console.error(ex);
         reject();
      });
   });
   return promise;
}

export function DeleteNewsletterSubscriptionRule(key) {
   let promise = new Promise(function (resolve, reject) {
   let uri = `SubscribeNewsletter/` + key;
   HttpClient.delete(uri)
      .then((res) => {
         resolve(res);
      })
      .catch((ex) => {
         console.error(ex);
         reject();
      });
   });
   return promise;
}

export function PutNewsletterSubscriptionRule(rule) {
   let promise = new Promise(function (resolve, reject) {
   let uri = `SubscribeNewsletter`;
   HttpClient.put(uri, rule)
      .then((res) => {
         resolve(res);
      })
      .catch((ex) => {
         console.error(ex);
         reject();
      });
   });
   return promise;
}

export function PostProblemReport(report) {
   let promise = new Promise(function (resolve, reject) {
   let uri = `ProblemReport`;
   HttpClient.post(uri, report)
      .then((res) => {
         resolve(res);
      })
      .catch((ex) => {
         console.error(ex);
         reject();
      });
   });
   return promise;
}
<template>
  <div>
    <div class="mt-4">
      <span class="mr-4">
        <font-awesome-icon icon="arrow-left" class="ln-primary mr-2" />
        <router-link
          class="ln-primary"
          :to="{
            name: `list`,
            params: { searchCache: searchCache, loadFromCache: loadFromCache },
          }"
        >
          Atgal
        </router-link>
      </span>
    </div>
  </div>
</template>

<script>
import SearchUrlParams from "../../_Helpers/search-url-params";
import { userService } from "../../_Services/user-service";

export default {
  name: "document-header",
  props: {
    fileGuid: String,
    searchCache: {
      type: Object,
      default: () => {
        return {
          documentList: undefined,
          currentPage: 0,
          resultPerPage: 10,
        };
      },
    },
  },
  created() {
    if (!this.loadFromCache) SearchUrlParams.clearSessionStorage();
  },
  computed: {
    loadFromCache() {
      return this.searchCache.documentList !== undefined;
    },
    isAuthenticated() {
      return userService.isAuthorized();
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>

<template>
   <div class="ln-border bg-white p-3 mt-4">
      <oops-component v-if="ready && err" />
      <div id="pdfvuer">
         <div v-if="ready && showDocument" class="embed-responsive" style="overflow-x: auto">
            <pdf v-for="i in numPages" :key="i" :id="i" :page="i" :src="pdfData" :scale.sync="scale" :style="pageStyle(i)" :resize="true">
               <template slot="loading"> loading content here... </template>
            </pdf>
         </div>
         <div v-if="!ready && showDocument" class="text-info">
            palaukite, ruošiamas dokumentas....
            <b-spinner id="spinner" variant="info" small class="ml-2"></b-spinner>
         </div>
         <div v-if="!showDocument" class="text-info">
            Dokumento <span @click="setShowDocument">negalima</span> atvaizduoti puslapyje. Bandykite atsisiųsti.
         </div>
      </div>
   </div>
</template>

<script>
import pdfvuer from 'pdfvuer';
import OopsComponent from '../../Shared/Components/oops-component';
import { FileRepo } from '../../_Services/repository';
export default {
   name: 'document-pdf-body',
   components: {
      pdf: pdfvuer,
      OopsComponent,
   },
   created() {
      if (this.fileGuid != null && this.showInWeb) this.downloadFile();
   },
   props: {
      fileGuid: {
         default: '',
         type: String,
      },
      showInWeb: {
         default: true,
         type: Boolean,
      },
   },
   computed: {},
   methods: {
      downloadFile() {
         FileRepo.get(this.fileGuid)
            .then(res => {
               let self = this;
               let pdfFile = URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
               this.pdfData = pdfvuer.createLoadingTask(pdfFile);
               this.pdfData
                  .then(pdf => {
                     self.numPages = pdf.numPages;
                  })
                  .catch(ex => {
                     this.err = true;
                     console.error('Klaida kraunant pdf', ex);
                  })
                  .finally(() => {
                     this.ready = true;
                  });
            })
            .catch(ex => {
               this.err = true;
               this.ready = true;
               console.error(ex);
            });
      },
      pageStyle(pg) {
         return pg === this.numPages ? 'width: 100%;padding: 10px;margin: 2px auto;' : 'width: 100%;padding: 2px;margin: 10px auto;border-bottom: 1px solid grey;';
      },
      setShowDocument() {
         this.showDocument = true;
         this.downloadFile();
      },
   },
   data() {
      return {
         showDocument: this.showInWeb,
         pdfData: undefined,
         page: 1,
         numPages: 0,
         ready: false,
         err: false,
         scale: 'page-width',
      };
   },
};
</script>
<style lang="css" scoped>
.pdfViewer {
   width: 100%;
   height: auto;
}
</style>

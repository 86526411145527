import '@babel/polyfill';
import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';
import VueLodash from 'vue-lodash';
import App from './App.vue';
import Router from './router';
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faDownload, faArrowLeft, faUser, faTimes, faChevronLeft, faEllipsisV, faInfoCircle, faExclamationTriangle, faPlus, faAngleUp, faAngleDown, faQuestionCircle, faSearch, faCopy, faCircle} from '@fortawesome/free-solid-svg-icons';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Datetime from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';
import { store } from './store';
import { Settings as LocaleSettings } from 'luxon';
import SiteStyles from './Assets/css/site.css';
import Paginate from 'vuejs-paginate';
import 'mime-types';
import VueRecaptcha from 'vue-recaptcha';
import jQuery from 'jquery';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';

LocaleSettings.defaultLocale = 'lt';

//console.log('VUE_APP_TITLE', process.env.VUE_APP_TITLE);
//console.log('VUE_APP_BASE_URL', process.env.VUE_APP_BASE_URL);

library.add(faDownload, faArrowLeft, faQuestionCircle, faUser, faTimes, faCalendar, faChevronLeft, faEllipsisV, faInfoCircle, faExclamationTriangle, faPlus, faAngleDown, faAngleUp, faSearch, faCopy, faCircle);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('vue-recaptcha', VueRecaptcha);
jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
   icons: {
      time: 'far fa-clock',
      date: 'far fa-calendar',
      up: 'fas fa-arrow-up',
      down: 'fas fa-arrow-down',
      previous: 'fas fa-chevron-left',
      next: 'fas fa-chevron-right',
      today: 'fas fa-calendar-check',
      clear: 'far fa-trash-alt',
      close: 'far fa-times-circle',
   },
});
Vue.use(BootstrapVue);
Vue.use(Datetime);
Vue.use(SiteStyles);
Vue.use(VueLodash, { name: 'lodash' });
Vue.use(VueCompositionAPI);

Vue.component('paginate', Paginate);

window.$ = window.jQuery = jQuery; //require('jquery');

new Vue({
   render: h => h(App),
   el: '#app',
   store,
   template: '',
   components: { App },
   router: Router,
   SiteStyles,
});

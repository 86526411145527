<template>
   <b-form-group label-cols-sm="12" label-cols-lg="2" content-cols-lg="7" label-align-lg="right" :label="label" label-class="ln-subtitle-text ln-darkblue noselect">
      <b-form-select class="ln-border" v-model="selectedVal" :options="options">
         <template v-slot:first>
            <option :value="''" disabled>{{ placehold }}</option>
         </template>
      </b-form-select>
   </b-form-group>
</template>

<script>
export default {
   name: 'select-search-block',
   props: {
      model: {
         type: [Number, String],
         required: true,
         default: '',
      },
      options: {
         default: new Array(),
         type: Array,
      },
      placehold: {
         default: 'Pasirinkite',
         type: String,
      },
      label: {
         default: '',
         type: String,
         required: true,
      },
   },
   computed: {
      selectedVal: {
         get: function () {
            return this.model;
         },
         set: function (val) {
            this.$emit('update:model', val);
         },
      },
   },
   data() {
      return {};
   },
};
</script>

<style scoped></style>

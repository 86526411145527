<template>
   <h2 class="text-center">404 - puslapis nerastas</h2>
</template>

<script>
export default {
   name: 'page-not-found',
};
</script>

<style lang="scss" scoped>
</style>
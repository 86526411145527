<template>
   <div>
      <reference-header v-if="metaReady" :documentId="documentMeta.id"></reference-header>
      <document-meta v-if="metaReady" :document="documentMeta" @loadList="loadList"></document-meta>
      <list-body v-if="listReady" :documentList="documentList" :currentPage="1" :resultPerPage="50" class="mt-4"></list-body>
      <div v-else>
         <b-spinner small variant="primary" label="Loading..." class="mr-1"></b-spinner>Palaukite, ruošiamas sąrašas....
         <hr />
      </div>
   </div>
</template>

<script>
import { GetDocumentMeta, GetDocumentList, GetPublicationsList } from '../Assets/repository';
import DocumentMeta from './Components/document-meta';
import ReferenceHeader from './Components/reference-header';
import ListBody from './Components/list-body';

export default {
   name: 'reference-page',
   components: { DocumentMeta, ListBody, ReferenceHeader },
   created() {
      this.loadMeta();
      this.loadList();
   },
   methods: {
      loadMeta() {
         let m_this = this;
         this.metaReady = false;
         GetDocumentMeta(this.$route.query.id, this.version).then(function(res) {
            m_this.documentMeta = res.data;
            m_this.metaReady = true;
         });
      },
      loadList() {
         let m_this = this;
         this.listReady = false;
         let url = `?id=${this.$route.query.id}&kiekis=200`;
         if (this.$route.query.klasId != null) url = url + `&klasId=${this.$route.query.klasId}`
         if (this.$route.query.status != null) url = url + `&status=${this.$route.query.status}`
         GetDocumentList(url)
         .then(res => {
               m_this.documentList = res.data;
               m_this.listReady = true;

               if (this.isPublicationsDisplayInSearchResultsEnabled) {
                  m_this.documentList.items.forEach(item => {
                     item.publicationsLoaded = false;
                  });
                  let docIdList = [];
                  for (let doc in m_this.documentList.items) {
                     docIdList.push(m_this.documentList.items[doc].id);
                  }
                  if (docIdList.length > 0) {
                     GetPublicationsList(docIdList.join(","))
                        .then(res => {
                           m_this.documentList.items.forEach(item => {
                              item.publications = res.data.filter(publication => publication.c_id === item.id);
                              item.publicationsLoaded = true;                      
                           });
                           m_this.documentList = {...m_this.documentList};
                        })
                        .catch(ex => {
                           console.error(ex);
                        });
                  }
               }
         });
      },
   },
   computed: {
      isPublicationsDisplayInSearchResultsEnabled() {
         return process.env.VUE_APP_SEARCH_DISPLAY_PUBLICATIONS === "true";
      },
   },
   data() {
      return {
         documentMeta: undefined,
         documentList: undefined,
         metaReady: false,
         listReady: false,
         version: 1,
      };
   },
};
</script>

<style lang="scss" scoped>
</style>
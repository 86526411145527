<template>
  <div>
    <div class="ln-border-8-wide ln-bg-light">
      <div class="row col-lg-9 col-md-12 p-4 position-relative">
        <div class="ln-doc-search-title ln-darkblue ml-4 noselect">Dokumentų paieška</div>
        <router-link :to="`/help`" class="btn ml-3 ln-primary ln-pagalba">
          <font-awesome-icon
            :icon="['fas', 'question-circle']"
            class="mr-1 ln-question"
          />
          <span class="ln-subtitle-text ln-darkblue">Pagalba</span>
        </router-link>
      </div>
      <b-form v-if="ready" v-on:keyup.enter="onSubmit">
        <b-form-group
          label-cols-sm="12"
          label-cols-lg="2"
          content-cols-lg="7"
          label-align-lg="right"
          label="Numeris"
          label-class="ln-subtitle-text ln-darkblue noselect"
        >
          <b-form-row>
            <b-col>
              <b-form-input
                class="ln-border"
                v-model="form.regNr"
                type="text"
              ></b-form-input>
            </b-col>
          </b-form-row>
        </b-form-group>
        <div class="ln-bck-darkergray pt-3 mt-1 pb-1 mb-3">
          <b-form-group
            label-cols-sm="12"
            label-cols-lg="2"
            content-cols-lg="7"
            label-align-lg="right"
            label="Žodžiai pavadinime"
            label-class="ln-subtitle-text ln-darkblue noselect"
          >
            <b-form-input
              class="ln-border"
              v-model="form.titleText"
              type="text"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            style="position: relative"
            label-cols-sm="12"
            label-cols-lg="2"
            content-cols-lg="7"
            label-align-lg="right"
            label="Žodžiai tekste"
            label-class="ln-subtitle-text ln-darkblue noselect"
          >
            <b-form-input
              class="ln-border"
              v-model="form.contentText"
              type="text"
            ></b-form-input>
            <div class="ln-chk-fraze-container">
              <div class="ln-fraze d-flex">
                <b-form-checkbox
                  v-model="form.textFraze"
                  value="true"
                  unchecked-value=""
                  size="lg"
                ></b-form-checkbox>
                <div class="ln-darkblue mt-1 noselect">Frazė</div>
              </div>
            </div>
          </b-form-group>
        </div>
        <select-search-block
          :model.sync="form.documentType"
          :options="clasifs.documentTypes"
          label="Dokumento rūšis"
          placehold="Pasirinkite"
        />

        <b-form-group
          label-cols-md="12"
          label-cols-lg="2"
          content-cols-lg="9"
          label-align-lg="right"
          label-class="ln-subtitle-text ln-darkblue noselect"
          label="Data"
        >
          <b-form-row>
            <b-col class="col-lg-3 col-md-5">
              <b-input-group>
                <date-picker
                  id="data_nuo"
                  v-model="form.dateFrom"
                  :config="dpoptions"
                  class="ln-border"
                  placeholder="Nuo"
                ></date-picker>
                <b-input-group-append @click="focusField('data_nuo')" class="ln-pointer">
                  <button class="ln-calendar-button ln-ignore-pointer" disabled>
                    <font-awesome-icon :icon="['fas', 'calendar']" />
                  </button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <div class="p-2">-</div>
            <b-col class="col-lg-3 col-md-5">
              <b-input-group>
                <date-picker
                  id="data_iki"
                  v-model="form.dateTo"
                  :config="dpoptions"
                  class="ln-border"
                  placeholder="Iki"
                ></date-picker>
                <b-input-group-append @click="focusField('data_iki')" class="ln-pointer">
                  <button class="ln-calendar-button ln-ignore-pointer" disabled>
                    <font-awesome-icon :icon="['fas', 'calendar']" />
                  </button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-form-row>
        </b-form-group>

        <b-collapse id="collapse-more-options" v-model="moreOptionsOpen">
          <select-search-block
            :model.sync="form.organization"
            :options="clasifs.organizations"
            label="Padalinys"
            placehold="Pasirinkite"
          />

          <select-search-block
            v-if="searchStatus"
            :model.sync="form.status"
            :options="clasifs.statuses"
            label="Būsena"
          />
          <select-search-block
            v-if="searchRubrik == true"
            :model.sync="form.rubrika"
            :options="clasifs.rubricators"
            label="Rubrikatorius"
          />
          <div v-if="searchPublication == true">
            <b-form-group
              label-cols-sm="12"
              label-cols-lg="2"
              content-cols-lg="7"
              label-align-lg="right"
              label="Publikuota"
              label-class="ln-subtitle-text ln-darkblue noselect"
            >
              <b-form-row>
                <b-col class="col-lg-1 col-md-3 col-sm-10 sr-only">
                  <span class="align-middle">Leidinys</span>
                </b-col>
                <b-col class="col-lg-5 col-md-4 col-sm-12">
                  <b-form-select
                    class="ln-border"
                    v-model="form.leidinys"
                    :options="clasifs.publicationTypes"
                  >
                    <template v-slot:first>
                      <option :value="''" disabled>Leidinys</option>
                    </template>
                  </b-form-select>
                </b-col>
                <b-col class="col-lg-1 col-md-3 col-sm-10 sr-only">
                  <span class="align-middle">Data</span>
                </b-col>
                <b-col class="col-lg-4 col-md-4 col-sm-12">
                  <b-input-group>
                    <date-picker
                      id="data_publ"
                      v-model="form.metai"
                      :config="dpoptions"
                      class="ln-border"
                      placeholder="Publ. data"
                    ></date-picker>
                    <b-input-group-append
                      @click="focusField('data_publ')"
                      class="ln-pointer"
                    >
                      <button class="ln-calendar-button ln-ignore-pointer" disabled>
                        <font-awesome-icon :icon="['fas', 'calendar']" />
                      </button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
                <b-col class="col-lg-1 col-md-3 col-sm-10 sr-only">
                  <span class="align-middle">Leidinio Nr.</span>
                </b-col>
                <b-col class="col-lg-3 col-md-4 col-sm-12">
                  <b-form-input
                    class="ln-border"
                    type="text"
                    v-model="form.pubnr"
                    placeholder="Publ. Nr."
                  ></b-form-input>
                </b-col>
              </b-form-row>
            </b-form-group>
          </div>

          <div v-if="searchStakeholders">
            <b-form-group
              label-cols-sm="12"
              label-cols-lg="2"
              content-cols-lg="7"
              label-align-lg="right"
              label="Rengėjas"
              label-class="ln-subtitle-text ln-darkblue noselect"
            >
              <b-form-input
                class="ln-border custom-select ln-datalist"
                list="stakeholders-list-id"
                type="text"
                v-model="form.rengejas"
                autocomplete
              ></b-form-input>
              <datalist id="stakeholders-list-id">
                <option v-for="item in clasifs.stakeholders" :key="item.value">{{ item.text }}</option>
              </datalist>
            </b-form-group>
          </div>

          <b-form-group
            v-if="quickSearchEnabled"
            label-cols-sm="12"
            label-cols-lg="2"
            content-cols-lg="7"
            label-align-lg="right"
            label="Greita paieška"
            label-class="ln-subtitle-text ln-darkblue noselect"
          >
            <b-form-row>
              <b-col class="ml-1 col-lg-2 col-md-4">
                <div
                  @click="quickSearch(6, $event)"
                  class="ln-subtitle-text-thin ln-primary ln-pointer pb-3"
                  style="padding-top: 7px"
                >
                  <u>6 mėn.</u>
                </div>
              </b-col>
              <b-col class="col-lg-2 col-md-4">
                <div
                  @click="quickSearch(12, $event)"
                  class="ln-subtitle-text-thin ln-primary ln-pointer pb-3"
                  style="padding-top: 7px"
                >
                  <u>12 mėn.</u>
                </div>
              </b-col>
            </b-form-row>
          </b-form-group>
        </b-collapse>

        <div class="row">
          <div class="col-lg-2 col-sm-10"></div>
          <div
            @click="moreOptionsOpen = !moreOptionsOpen"
            class="col-lg-2 ln-subtitle-text ln-primary ln-pointer"
          >
            {{ moreOptionsOpen ? "Mažiau" : "Daugiau" }}
            <font-awesome-icon
              v-if="!moreOptionsOpen"
              :icon="['fas', 'angle-down']"
              size="lg"
              class="ml-2 mr-2 ln-primary"
            />
            <font-awesome-icon
              v-if="moreOptionsOpen"
              :icon="['fas', 'angle-up']"
              size="lg"
              class="ml-2 mr-2 ln-primary"
            />
          </div>
        </div>

        <div class="ln-bt-g mt-3"></div>

        <div class="row mt-4 mb-4">
          <div class="col-lg-2 col-sm-2"></div>
          <div class="col-lg-3 col-sm-5">
            <b-button
              class="w-100 ml-0 pt-3 pb-3 ln-subtitle-text"
              type="button"
              variant="primary"
              @click="onSubmit"
            >
              <font-awesome-icon :icon="['fas', 'search']" class="mr-2" />
              Ieškoti
            </b-button>
          </div>
          <div class="col-lg-3 col-sm-5">
            <div
              class="w-100 pt-3 pb-3 ln-subtitle-text ln-darkblue ln-hover text-center"
              @click="onReset"
            >
              <font-awesome-icon :icon="['fas', 'times']" class="mr-2 ln-darkblue-half" />
              Išvalyti
            </div>
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import SearchUrlParams from "../_Helpers/search-url-params";
import SelectSearchBlock from "./Components/SearchBlocks/select-search-block";
import datePicker from "vue-bootstrap-datetimepicker";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import { GetClassificators } from "@/Assets/repository";

export default {
  name: "search-page",
  props: { cacheIsNeeded: Boolean },
  components: { datePicker, SelectSearchBlock },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      this.$router.push("list" + this.queryParams);
      this.$emit("searchEvent");
    },
    onReset(evt) {
      evt.preventDefault();
      this.$store.dispatch("clearSearchCache");
      this.form = {
        regNr: "",
        dateFrom: "",
        dateTo: "",
        organization: "",
        documentType: "",
        titleText: "",
        contentText: "",
        status: "",
        rubrika: "",
        leidinys: "",
        metai: "",
        leidnr: "",
        pubnr: "",
        isplestine: "",
        textFraze: "",
        rengejas: "",
      };
    },
    focusField(id) {
      document.getElementById(id).focus();
    },
    quickSearch(range, event) {
      let dateTo = new Date();
      let dateFrom = new Date();
      dateFrom.setMonth(dateFrom.getMonth() - range);
      this.form.dateTo = dateTo.toISOString().slice(0, 10);
      this.form.dateFrom = dateFrom.toISOString().slice(0, 10);
      this.onSubmit(event);
    },
    saveTokenFromQuery() {
      let token = this.$route.query.token;
      let tokenExpires = this.$route.query.tokenExpires;
      if (!!token && !!tokenExpires) {
        localStorage.setItem("token", token);
        localStorage.setItem("tokenExpires", tokenExpires);
        localStorage.setItem("passwordExpired", "false");
        location.replace(process.env.VUE_APP_BASE_URL);
      }
    },
  },
  mounted() {
    this.saveTokenFromQuery();
    GetClassificators()
      .then((res) => {
        this.clasifs = res.data;
        this.ready = true;
      })
      .catch((ex) => {
        console.error(ex);
      });
    SearchUrlParams.bind(this.form, this.$route.query);
    if (this.cacheIsNeeded === false) {
      this.$store.dispatch("clearSearchCache");
    }
  },
  created() {},
  computed: {
    queryParams() {
      return SearchUrlParams.encode({
        nr: this.form.regNr,
        nuo: this.form.dateFrom,
        iki: this.form.dateTo,
        padalinys: this.form.organization,
        dokRusys: this.form.documentType,
        pavadinimas: this.form.titleText,
        tekstas: this.form.contentText,
        status: this.form.status,
        rubrika: this.form.rubrika,
        leidinys: this.form.leidinys,
        metai: this.form.metai,
        leidnr: this.form.leidnr,
        pubnr: this.form.pubnr,
        isplestine: this.moreOptionsOpen,
        textFraze: this.form.textFraze,
        rengejas: this.form.rengejas,
      });
    },
    searchRubrik() {
      return process.env.VUE_APP_SEARCH_RUBRIK === "true";
    },
    searchStatus() {
      return process.env.VUE_APP_SEARCH_STATUS === "true";
    },
    searchPublication() {
      return process.env.VUE_APP_SEARCH_PUBLICATION === "true";
    },
    quickSearchEnabled() {
      return process.env.VUE_APP_QUICK_SEARCH === "true";
    },
    searchStakeholders() {
      return process.env.VUE_APP_SHOW_STAKEHOLDERS === "true";
    }
  },
  data() {
    return {
      moreOptionsOpen: this.$route.query.isplestine === "true" ? true : false,
      form: {
        regNr: "",
        dateFrom: "",
        dateTo: "",
        organization: "",
        documentType: "",
        titleText: "",
        contentText: "",
        status: "",
        rubrika: "",
        leidinys: "",
        metai: "",
        leidnr: "",
        pubnr: "",
        isplestine: "",
        textFraze: "",
        rengejas: "",
      },
      clasifs: {},
      ready: false,
      dpoptions: {
        format: "YYYY-MM-DD",
        useCurrent: false,
        locale: "lt",
        showTodayButton: true,
        showClear: true,
      },
    };
  },
};
</script>

<style lang="css" scoped></style>

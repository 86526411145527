<template>
   <div class="container">
      <div class="row">
         <div class="bg-white ln-darkblue text-center ln-subtitle-text-thin" id="found_text">Rasta: <span class="ln-subtitle-text">{{documentCount}}</span></div>
      </div>

      <div class="ln-bt-g mt-3" style="margin-right:-15px;margin-left:-15px;"></div>

      <div class="row">
         <div class="bg-white" colspan="2">
            <table>
               <tr v-for="(item, index) in listItems" :key="index">
                  <td class="align-text-top font-weight-bold pr-2 pt-4">{{itemIndex(index)}}.</td>
                  <td class="pt-4">
                     <div>
                        <router-link class="ln-primary ln-subtitle-text-thin" :to="{ name: `document`, params: { id: item.id }}">{{item.title}}</router-link>
                     </div>
                     <div>
                        <span class="ln-bckg-darkblue ln-sm-text-bold pr-2 pl-2">{{item.regNr}}</span>
                        <span class="ln-sm-text pl-2 ln-darkblue">{{item.signDate}}</span>
                     </div>
                     <div>
                        <span class="ln-sm-text ln-darkblue">{{item.organization ? fixCapitalization(item.organization) : ""}},</span>
                        <span class="ln-sm-text-bold pl-2 ln-darkblue">{{item.name}}</span>
                     </div>
                     <div class="ln-sm-text-bold ln-darkblue">
                        <font-awesome-icon
                           style="width: 0.5em; margin-right: 0.3em"
                           :class="'ln-sviesoforas-' + item.statusCode"
                           icon="circle"
                        />
                        {{getStatusName(item)}}
                     </div>
                     <div v-if="isPublicationsDisplayInSearchResultsEnabled">
                        <b-spinner v-if="!item.publicationsLoaded" variant="primary" style="width: 0.5em; height: 0.5em; margin-left: 2px"></b-spinner>
                        <div v-if="item.publicationsLoaded && item.publications && item.publications.length > 0" class="d-flex mt-1">
                           <div class="ln-sm-text-bold ln-darkblue">Publikacijos:</div>
                           <div class="ml-2">
                              <div v-for="(publication, index) in item.publications" :key="index" class="ln-sm-text">
                                    <span class="ln-sm-text ln-darkblue">{{publication.name}} </span>
                                    <span class="ln-sm-text ln-darkblue">, Data: {{publication.publ_date.slice(0,10)}}</span>
                                    <span class="ln-sm-text ln-darkblue">, Publikacijos Nr.: {{publication.publ_nr}}</span>
                              </div>
                           </div>
                        </div>
                     </div>
                  </td>
               </tr>
            </table>
         </div>
      </div>
   </div>
</template>

<script>
import { normalizeCapitalization } from "../../_Services/repository";

export default {
   name: 'list-body',
   props: {
      documentList: Object,
      currentPage: Number,
      resultPerPage: Number,
   },
   methods: {
      itemIndex(index) {
         return (this.currentPage - 1) * this.resultPerPage + index + 1;
      },
      getStatusName(item) {
         switch(item.statusCode) {
            case 'success':
               return "GALIOJA";
            case 'warning':
               return "ĮSIGALIOS";
            case 'danger':
               return "NEGALIOJA";
            default:
               return item.statusName ? item.statusName.toUpperCase() : "";
         }
      },
      fixCapitalization(text) {
         return normalizeCapitalization(text);
      },
   },
   mounted() {
      document.getElementById("found_text").scrollIntoView({behavior: "smooth", block: "center"});
   },
   computed: {
      documentCount() {
         //return Math.ceil(this.documentList.count / this.resultPerPage);
         return Math.ceil(this.documentList.count);
      },
      listItems() {
         return this.documentList.items;
      },
      isPublicationsDisplayInSearchResultsEnabled() {
         return process.env.VUE_APP_SEARCH_DISPLAY_PUBLICATIONS === "true";
      },
   },
};
</script>

<style lang="scss" scoped>
</style>
<template>
   <div>
      <div class="m-5 text-center">
         <font-awesome-icon :icon="['fas', 'exclamation-triangle']" size="5x" class="mr-1 text-warning" />
         <h2 class="mt-3">Klaida!</h2>
      </div>
   </div>
</template>

<script>
export default {
   name: 'oops-component',
};
</script>

<style lang="scss" scoped></style>

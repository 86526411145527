<template>
   <div>
      <b-card no-body class="mb-1">
         <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button size="md" block v-b-toggle="'accordion-' + model.key" variant="light">
               {{model.key}} - {{model.email}} - {{this.frequencyOptions.find(i => i.value === frequencySelected).text}}
               <div class="float-right d-inline bg-transparent" variant="light" @click="showModal=true"><font-awesome-icon :icon="['fas','times']" /></div>
            </b-button>
         </b-card-header>
         <b-collapse :id="'accordion-' + model.key" accordion="my-accordion" role="tabpanel">
            <b-card-body>
               <div class="d-inline ln-subtitle-text">Prenumeratos taisyklė {{model.key}}</div>
               <b-button class="float-right d-inline" variant="primary" @click="validateAndSaveRule">Išsaugoti</b-button>
               <b-form-group
                  label="Elektroninio pašto adresas:"
                  label-for="ctrl_email"
                  label-size="md"
                  invalid-feedback="Privalomas teisingas elektroninio pašto adresas"
                  class="mt-3"
                  >
                  <b-form-input
                     v-model="model.email"
                     :id="'email-' + model.key"
                     type="email"
                     :state="validEmail(model.email)"
                     class="w-50"
                  >
                  </b-form-input>
               </b-form-group>
               <b-form-group label="Naujienlaiškio dažnumas:" label-size="md">
                  <b-form-select
                     class="w-25"
                     v-model="frequencySelected"
                     :options="frequencyOptions"
                     :id="'select-frequency-' + model.key"
                     size="md"
                     @change="handleFrequencyChange">
                  </b-form-select>
               </b-form-group>
               <div>
                  <b-form-select
                     v-if="showMonthDays"
                     class="d-inline w-25"
                     v-model="monthDaySelected"
                     :options="monthDayOptions"
                     size="md"
                     @change="handleFrequencyChange">
                  </b-form-select>
                  <b-form-select
                     v-if="showWeekDays"
                     class="d-inline w-25"
                     v-model="weekDaySelected"
                     :options="weekDaysOptions"
                     size="md"
                     @change="handleFrequencyChange">
                  </b-form-select>
                  <b-form-select 
                     v-if="showHours" 
                     class="d-inline w-25" 
                     v-model="hoursSelected"
                     :options="hoursOptions"
                     size="md"
                     @change="handleFrequencyChange">
                  </b-form-select>
               </div>
               <div class="form-group mt-3">
                  <label class="form-label col-form-label col-form-label-md" :for="'doctypes-' + model.key">Dokumentų tipai:</label>
                  <Multiselect
                     v-model="selectedVal.documentTypes"
                     mode="tags"
                     :close-on-select="false"
                     :options="clasifs"
                     label="text"
                     :id="'doctypes-' + model.key"
                     v-bind:class="{ custom_css_red_error: selectedVal.documentTypes.length < 1 }"
                  />
               </div>
            </b-card-body>
         </b-collapse>
      </b-card>
      <b-modal :id="'delete-confirmation-modal' + model.key" centered v-model="showModal">
         <p class="my-4">Ar tikrai norite panaikinti prenumeratos taisyklę?</p>
         <template #modal-footer>             
            <b-button size="sm" variant="danger" @click="showModal=false">Ne</b-button>
            <b-button size="sm" variant="success" @click="showModal=false; $emit('deleteRule', model.key)">Taip</b-button>        
         </template>
      </b-modal>
   </div>
</template>

<script>
import { PostNewsletterSubscriptionRule, PutNewsletterSubscriptionRule } from '@/Assets/repository';
import Multiselect from '@vueform/multiselect/dist/multiselect.vue2.js';

export default {
   name: 'newsletter-card',
   props: {
      model: Object,
      clasifs: Array
   },
   components: { Multiselect },
   mounted() {
      const hours = this.model.sendingHour;
      const weekDay = this.model.sendingWeekDay;
      const monthDay = this.model.sendingMonthDay;
      const id = this.model.id;

      if (id === null) {
         this.frequencySelected = 'immediate';
      }
      else if (hours === undefined) {
         this.frequencySelected = 'immediate';
      }
      else if (weekDay === undefined & monthDay === undefined) {
         this.frequencySelected = 'daily';
         this.hoursSelected = hours;
      }
      else if (weekDay !== undefined) {
         this.frequencySelected = 'weekly';
         this.hoursSelected = hours;
         this.weekDaySelected = weekDay;
      }
      else if (monthDay !== undefined) {
         this.frequencySelected = 'monthly';
         this.hoursSelected = hours;
         this.monthDaySelected = monthDay;
      }
      this.handleFrequencyChange();
   },
   computed: {
      selectedVal: {
         get: function () {
            return this.model;
         },
         set: function (val) {
            this.$emit('update:model', val);
         }
      }
   },
   methods: {
      validEmail(email) {
         const re = /(.+)@(.+){2,}\.(.+){2,}/;
         return re.test(email.toLowerCase());
      },
      validDocTypes() {
         return this.selectedVal.documentTypes.length > 0;
      },
      handleFrequencyChange(){
         switch(this.frequencySelected) {
            case 'immediate':
                  this.showHours = false;
                  this.showWeekDays = false;
                  this.showMonthDays = false;
                  this.selectedVal.sendingHour = null;
                  this.selectedVal.sendingWeekDay = null;
                  this.selectedVal.sendingMonthDay = null;
               break;
            case 'daily':
                  this.showHours = true;
                  this.showWeekDays = false;
                  this.showMonthDays = false;
                  this.selectedVal.sendingHour = this.hoursSelected;
                  this.selectedVal.sendingWeekDay = null;
                  this.selectedVal.sendingMonthDay = null;
               break;
            case 'weekly':
                  this.showHours = true;
                  this.showWeekDays = true;
                  this.showMonthDays = false;
                  this.selectedVal.sendingHour = this.hoursSelected;
                  this.selectedVal.sendingWeekDay = this.weekDaySelected;
                  this.selectedVal.sendingMonthDay = null;
               break;
            case 'monthly':
                  this.showHours = true;
                  this.showWeekDays = false;
                  this.showMonthDays = true;
                  this.selectedVal.sendingHour = this.hoursSelected;
                  this.selectedVal.sendingMonthDay = this.monthDaySelected;
                  this.selectedVal.sendingWeekDay = null;
               break;
         }
      },
      validateAndSaveRule() {
         if (!this.validEmail(this.model.email) || !this.validDocTypes()) {
            this.makeToast('danger', 'Netinkamai įvesti duomenys', 5000, ' ');
            return;
         }
         if (this.model.id === null) {
            this.saveNewRule();
         } else {
            this.updateExistingRule();
         }
      },
      saveNewRule() {
         let rule = {
            email: this.model.email,
            sendingHour: this.model.sendingHour,
            sendingWeekDay: this.model.sendingWeekDay,
            sendingMonthDay: this.model.sendingMonthDay,
            documentTypes: this.model.documentTypes
         };
         PostNewsletterSubscriptionRule(rule)
            .then((res) => {
               this.selectedVal.id = res.data;
               this.makeToast('success', 'Prenumeratos taisyklė išsaugota sėkmingai', 1500);
               })
               .catch((ex) => {
               console.error(ex);
               this.makeToast('danger', 'Klaida išsaugant prenumeratos taisyklę', 5000);
         });
      },
      updateExistingRule() {
         let rule = {
            id: this.model.id,
            email: this.model.email,
            sendingHour: this.model.sendingHour,
            sendingWeekDay: this.model.sendingWeekDay,
            sendingMonthDay: this.model.sendingMonthDay,
            documentTypes: this.model.documentTypes
         };
         PutNewsletterSubscriptionRule(rule)
            .then(() => {
               this.makeToast('success', 'Prenumeratos taisyklė išsaugota sėkmingai', 1500);
               })
               .catch((ex) => {
               console.error(ex);
               this.makeToast('danger', 'Klaida išsaugant prenumeratos taisyklę', 5000);
         });
      },
      makeToast(variant, title, delay) {
         this.$bvToast.toast(' ', {
          title: title,
          variant: variant,
          toaster: 'b-toaster-bottom-right',
          autoHideDelay: delay,
          solid: true
         });
      }
   },
   data() {
      return {
         showModal: false,
         showHours: false,
         showWeekDays: false,
         showMonthDays: false,
         frequencySelected: 'immediate',
         frequencyOptions: [
            { text: 'Iškart', value: 'immediate' },
            { text: 'Kasdien', value: 'daily' },
            { text: 'Kas savaitę', value: 'weekly' },
            { text: 'Kas mėnesį', value: 'monthly' }
         ],
         hoursSelected: 8,
         hoursOptions: [
            { text: '01:00', value: 1 },
            { text: '02:00', value: 2 },
            { text: '03:00', value: 3 },
            { text: '04:00', value: 4 },
            { text: '05:00', value: 5 },
            { text: '06:00', value: 6 },
            { text: '07:00', value: 7 },
            { text: '08:00', value: 8 },
            { text: '09:00', value: 9 },
            { text: '10:00', value: 10 },
            { text: '11:00', value: 11 },
            { text: '12:00', value: 12 },
            { text: '13:00', value: 13 },
            { text: '14:00', value: 14 },
            { text: '15:00', value: 15 },
            { text: '16:00', value: 16 },
            { text: '17:00', value: 17 },
            { text: '18:00', value: 18 },
            { text: '19:00', value: 19 },
            { text: '20:00', value: 20 },
            { text: '21:00', value: 21 },
            { text: '22:00', value: 22 },
            { text: '23:00', value: 23 },
            { text: '24:00', value: 24 }
         ],
         weekDaySelected: 1,
         weekDaysOptions: [
            { text: 'Pirmadieniais', value: 1 },
            { text: 'Antradieniais', value: 2 },
            { text: 'Trečiadieniais', value: 3 },
            { text: 'Ketvirtadieniais', value: 4 },
            { text: 'Penktadieniais', value: 5},
            { text: 'Šeštadieniais', value: 6 },
            { text: 'Sekmadieniais', value: 7 }
         ],
         monthDaySelected: 1,
         monthDayOptions: [
            { text: '1 mėnesio dieną', value: 1 },
            { text: '2 mėnesio dieną', value: 2 },
            { text: '3 mėnesio dieną', value: 3 },
            { text: '4 mėnesio dieną', value: 4 },
            { text: '5 mėnesio dieną', value: 5 },
            { text: '6 mėnesio dieną', value: 6 },
            { text: '7 mėnesio dieną', value: 7 },
            { text: '8 mėnesio dieną', value: 8 },
            { text: '9 mėnesio dieną', value: 9 },
            { text: '10 mėnesio dieną', value: 10 },
            { text: '11 mėnesio dieną', value: 11 },
            { text: '12 mėnesio dieną', value: 12 },
            { text: '13 mėnesio dieną', value: 13 },
            { text: '14 mėnesio dieną', value: 14 },
            { text: '15 mėnesio dieną', value: 15 },
            { text: '16 mėnesio dieną', value: 16 },
            { text: '17 mėnesio dieną', value: 17 },
            { text: '18 mėnesio dieną', value: 18 },
            { text: '19 mėnesio dieną', value: 19 },
            { text: '20 mėnesio dieną', value: 20 },
            { text: '21 mėnesio dieną', value: 21 },
            { text: '22 mėnesio dieną', value: 22 },
            { text: '23 mėnesio dieną', value: 23 },
            { text: '24 mėnesio dieną', value: 24 },
            { text: '25 mėnesio dieną', value: 25 },
            { text: '26 mėnesio dieną', value: 26 },
            { text: '27 mėnesio dieną', value: 27 },
            { text: '28 mėnesio dieną', value: 28 },
            { text: '29 mėnesio dieną', value: 29 },
            { text: '30 mėnesio dieną', value: 30 },
            { text: '31 mėnesio dieną', value: 31 }
         ],
      }
   }
};
</script>
<style></style>
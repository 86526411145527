import { render, staticRenderFns } from "./reference-header.vue?vue&type=template&id=9f39b7ca&scoped=true&"
import script from "./reference-header.vue?vue&type=script&lang=js&"
export * from "./reference-header.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9f39b7ca",
  null
  
)

export default component.exports
<template>
  <div>
    <h2>Pranešti apie problemą</h2>
    <hr />
    <b-form @submit="onSubmit">
      <b-form-group
        label="Dokumento Id"
        label-for="report-problem-page-id"
        label-class="ln-subtitle-text ln-darkblue"
      >
        <b-form-input id="input-1" v-model="form.id" readonly></b-form-input>
      </b-form-group>
      <!-- ------------------- -->
      <b-form-group
        label="Dokumento versija"
        label-for="report-problem-page-id"
        label-class="ln-subtitle-text ln-darkblue"
      >
        <b-form-input id="input-1" v-model="form.version" readonly></b-form-input>
      </b-form-group>
      <!-- ------------------- -->
      <b-form-group
        label="Trumpai aprašykite problemą"
        label-for="report-problem-page-report"
        label-class="ln-subtitle-text ln-darkblue"
        :invalid-feedback="invalidFeedback"
      >
        <b-form-textarea
          id="report-problem-page-report"
          class="ln-border"
          v-model="form.report"
          required
          :state="state"
        ></b-form-textarea>
      </b-form-group>

      <b-form-group
        label="Įtraukiami diagnostiniai duomenys:"
        label-for="report-include"
        label-class="ln-subtitle-text ln-darkblue"
      >
        <b-form-checkbox-group
          id="report-include"
          v-model="includeResultArray"
          :options="includeOptions"
          :state="includeCheckboxState"
          stacked
        >
          <b-form-invalid-feedback :state="includeCheckboxState"
            >Turi būti pasirinkta bent 1</b-form-invalid-feedback
          >
        </b-form-checkbox-group>
      </b-form-group>
      <hr />
      <b-button
        type="submit"
        variant="outline-primary"
        :disabled="!state || !includeCheckboxState"
      >
        Siųsti
      </b-button>
    </b-form>
  </div>
</template>
<script>
import { PostProblemReport } from "@/Assets/repository";

export default {
  name: "report-problem-page",
  created() {
    // console.log(this.$route.params);
    this.form.id = this.$route.params.id;
    this.form.version = this.$route.params.version;
  },
  methods: {
    onSubmit(e) {
      // console.log("POST ProblemReport", this.form, this.includeSelectedString);
      e.preventDefault();
      let report = { ...this.form, include: this.includeSelectedString };

      PostProblemReport(report)
        .then(() => {
          // console.log(res);
          this.makeToast("success", "Pranešimas apie problemą pateiktas sėkmingai", 1500);
        })
        .catch((ex) => {
          console.error(ex);
          this.makeToast("danger", "Klaida pateikiant pranešimą apie problemą", 5000);
        });
    },
    makeToast(variant, title, delay) {
      this.$bvToast.toast(" ", {
        title: title,
        variant: variant,
        toaster: "b-toaster-bottom-right",
        autoHideDelay: delay,
        solid: true,
      });
    },
  },
  computed: {
    state() {
      return this.form.report.length >= 3;
    },
    invalidFeedback() {
      return "Laukas privalomas";
    },
    includeCheckboxState() {
      return this.includeResultArray.length > 0;
    },
    includeSelectedString() {
      return this.includeResultArray ? this.includeResultArray.join(";") : null;
    },
  },
  data() {
    return {
      form: {
        id: 0,
        version: 1,
        report: "",
      },
      includeResultArray: [
        "GetSingleDocumentXml",
        "Card",
        "Publications",
        "DocLink",
        "Document",
        "Codif",
        "File",
        "CommentSentLog",
      ],
      includeOptions: [
        { text: "Dokumento metaduomenys", value: "GetSingleDocumentXml" },
        { text: "Dokumento kortelės duomenys", value: "Card" },
        { text: "Publikacijos", value: "Publications" },
        { text: "Ryšiai", value: "DocLink" },
        { text: "Dokumento versijos duomenys", value: "Document" },
        { text: "Klasifikatoriai", value: "Codif" },
        { text: "Byla", value: "File" },
        { text: "Komentarai", value: "CommentSentLog" },
      ],
    };
  },
};
</script>

<template>
   <div>
      <b-form @submit="onSubmit" :validated="true">
         <b-form-group
            label="Jūsų elektroninis paštas"
            label-for="email"
            label-class="ln-text-primary font-weight-bold">
            <b-form-input id="email" v-model="email" required type="email"></b-form-input>
            </b-form-group>
             
            <b-form-group
            label="Naudotojo vardas"
            label-for="username"
            label-class="ln-text-primary font-weight-bold">
            <b-form-input id="username" v-model="username" required></b-form-input>
         </b-form-group>
         <b-button type="submit" :disabled="sendDisabled" class="ln-bnt-primary">Siųsti</b-button>
         <div v-if="wrongEmail" class="text-danger">Naudotojo el. paštas nerastas</div>
      </b-form>
   </div>
</template>

<script>
import { setTimeout } from 'timers';
import { userService } from '@/_Services/user-service';
export default {
   name: 'remember-password-form',
   methods: {
      onSubmit(evt) {
         evt.preventDefault();
         this.wrongEmail = true;
         userService.sendRestorePasswordAndExit(this.username,this.email)
      },
      onVerify: function() {
         setTimeout(() => (this.sendDisabled = false), 1000);
      },
   },
   data() {
      return {
         wrongEmail: false,
         form:{
          email: '',
          username:'',
         },
         sendDisabled: false,
      };
   },
};
</script>

<style lang="scss" scoped>
</style>
<template>
  <div>
    <div v-if="isAuthenticated" class="text-right ln-report-problem-container">
      <span class="mr-0 ln-list-ref ln-primary ln-hover" @click="reportProblem">
        <font-awesome-icon style="width: 12px" :icon="['fas', 'exclamation-triangle']" />
        Pranešti apie problemą
      </span>
    </div>
    <div class="ln-doc-title ln-darkblue mt-3 mb-3">
      <div v-if="isClipboardAccessible" class="ml-3 mb-4 ln-copy" @click="copyTitle">
        <font-awesome-icon id="copy-icon" :icon="['fas', 'copy']" />
        <b-tooltip
          target="copy-icon"
          variant="secondary"
          placement="bottom"
          triggers="hover"
          >Kopijuoti citavimui</b-tooltip
        >
      </div>
      {{ document.title }}
    </div>
    <div class="mb-3"></div>
    <div class="ln-border-8 ln-bg-light">
      <div class="p-4">
        <div style="float: right">
          <div>
            <font-awesome-icon
              class="ln-primary"
              icon="info-circle"
              @click="makeToast('info')"
              v-if="this.document.notes != null"
            />
            <span class="ml-3 ln-subtitle-text ln-darkblue">
              {{ statusName }}
              <font-awesome-icon
                style="width: 1em"
                :class="['ln-sviesoforas-' + document.statusCode, 'ml-1']"
                icon="circle"
              />
            </span>
          </div>
          <div v-if="document.actualTo" style="float: right">
            <span class="ln-subtitle-text-thin">{{ getExpirationDateForDisplay() }}</span>
          </div>
        </div>
        <div class="ln-darkblue">
          <div>
            <span class="ln-subtitle-text-thin"
              >{{ document.name ? fixCapitalization(document.name) : "" }}:</span
            >
            <span class="ml-2 ln-subtitle-text">Nr. {{ document.regNr }} </span>
            <span class="ml-2 ln-subtitle-text-thin">{{ document.signDate }}</span>
          </div>
          <div>
            <span class="ln-subtitle-text-thin">Padalinys:</span>
            <span class="ml-2 ln-subtitle-text">{{
              document.organization ? fixCapitalization(document.organization) : " "
            }}</span>
          </div>
        </div>
        <div class="ln-darkblue d-flex justify-content-start">
          <document-reference-link
            v-if="renderReferences"
            :references="document.references"
            :inoperatives="document.inoperatives"
            :docId="document.id"
            :appendicesLength="appendicesLength"
            @getReferenceList="getReferenceList"
            @loadList="loadList"
          ></document-reference-link>
        </div>
      </div>
      <div
        v-if="
          showStakeholders && document.stakeholders && document.stakeholders.length > 0
        "
      >
        <div class="ln-bt-g"></div>
        <div
          @click="stakeholdersOpen = !stakeholdersOpen"
          class="ln-subtitle-text-thin ln-darkblue p-3 ln-pointer"
        >
          <font-awesome-icon
            v-if="!stakeholdersOpen"
            :icon="['fas', 'angle-down']"
            size="lg"
            class="ml-2 mr-2 ln-primary"
          />
          <font-awesome-icon
            v-if="stakeholdersOpen"
            :icon="['fas', 'angle-up']"
            size="lg"
            class="ml-2 mr-2 ln-primary"
          />
          Teisėkūros proceso dalyviai
        </div>
        <b-collapse id="collapse-stakeholders" v-model="stakeholdersOpen" class="pb-2">
          <div
            v-for="(item, index) in stakeholdersList"
            :key="index"
            class="ln-subtitle-text-thin ln-darkblue ml-5 pb-3"
          >
            <div class="d-flex">
              <div class="ln-subtitle-text">{{ item.type }}:</div>
              <div class="ml-2">
                <div v-for="(person, i) in stakeholdersList[index].list" :key="i">
                  {{ person.vardas }}
                </div>
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
      <div
        v-if="
          publicationsEnabled && document.publications && document.publications.length > 0
        "
      >
        <div class="ln-bt-g"></div>
        <div
          @click="publicationsOpen = !publicationsOpen"
          class="ln-subtitle-text-thin ln-darkblue p-3 ln-pointer"
        >
          <font-awesome-icon
            v-if="!publicationsOpen"
            :icon="['fas', 'angle-down']"
            size="lg"
            class="ml-2 mr-2 ln-primary"
          />
          <font-awesome-icon
            v-if="publicationsOpen"
            :icon="['fas', 'angle-up']"
            size="lg"
            class="ml-2 mr-2 ln-primary"
          />
          Publikacijos
        </div>
        <b-collapse id="collapse-publications" v-model="publicationsOpen" class="pb-2">
          <div
            v-for="(item, index) in document.publications"
            :key="index"
            class="ln-subtitle-text-thin ln-darkblue ml-5 pb-3"
          >
            <span class="ln-subtitle-text">{{ item.leidinys }}</span
            >, Data: {{ item.data }}, Leidinio Nr.: {{ item.leidinioNr }}, Publikacijos
            Nr.: {{ item.publikacijosNr }}
          </div>
        </b-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import DocumentReferenceLink from "./document-reference-link";
import { userService } from "../../_Services/user-service";
import { normalizeCapitalization } from "../../_Services/repository";

export default {
  name: "document-meta",
  components: { DocumentReferenceLink },
  props: {
    document: Object,
  },
  created() {
    if (this.document.notes !== null) {
      this.makeToast("info");
    }
    this.prepareStakeholdersListForDisplay();
  },
  computed: {
    appendicesLength() {
      if (!this.document) return 0;
      return this.document.appendices.length;
    },
    publicationsEnabled() {
      return process.env.VUE_APP_SEARCH_PUBLICATION === "true";
    },
    showStakeholders() {
      return process.env.VUE_APP_SHOW_STAKEHOLDERS === "true";
    },
    renderReferences() {
      return (
        (this.document.references && this.document.references.length > 0) ||
        (this.document.inoperatives && this.document.inoperatives.length > 0) ||
        this.appendicesLength > 0
      );
    },
    statusName() {
      switch (this.document.statusCode) {
        case "success":
          return "GALIOJA";
        case "warning":
          return "ĮSIGALIOS";
        case "danger":
          return "NEGALIOJA";
        default:
          return this.document.statusName ? this.document.statusName.toUpperCase() : "";
      }
    },
    isAuthenticated() {
      return userService.isAuthorized();
    },
  },
  methods: {
    getReferenceList(klasId) {
      this.$emit("getReferenceList", klasId);
    },
    loadList() {
      this.$emit("loadList");
    },
    makeToast(variant = "info") {
      this.$bvToast.toast(this.document.notes, {
        title: `Žyma`,
        variant: variant,
        solid: true,
        autoHideDelay: 30000,
      });
    },
    copyTitle() {
      navigator.clipboard.writeText(this.document ? this.document.title : "");
    },
    reportProblem() {
      this.$router.push(`/reportproblem/${this.document.id}/${this.document.version}`);
    },
    prepareStakeholdersListForDisplay() {
      if (
        !!this.showStakeholders &&
        this.document.stakeholders &&
        this.document.stakeholders.length > 0
      ) {
        let stakeholderTypeIdList = [
          ...new Set(this.document.stakeholders.map((item) => item.tipasId).sort()),
        ];
        stakeholderTypeIdList.forEach((type) => {
          this.stakeholdersList.push({
            type: this.document.stakeholders.find((item) => item.tipasId === type).tipas,
            list: this.document.stakeholders.filter((item) => item.tipasId === type),
          });
        });
      }
    },
    getExpirationDateForDisplay() {
      let expirationDate = new Date(this.document.actualTo.slice(0, 10));
      let now = new Date();
      now.setHours(12);
      expirationDate.setHours(23);
      let isExpired = expirationDate < now;
      if (isExpired) {
        expirationDate.setDate(expirationDate.getDate() + 1);
      }
      let expirationDateForDisplay = expirationDate.toISOString().slice(0, 10);
      return isExpired
        ? `Nuo: ${expirationDateForDisplay}`
        : `Iki: ${expirationDateForDisplay}`;
    },
    fixCapitalization(text) {
      return normalizeCapitalization(text);
    },
  },
  data() {
    return {
      publicationsOpen: false,
      stakeholdersOpen: false,
      stakeholdersList: [],
      isClipboardAccessible: location.protocol === "https:",
    };
  },
};
</script>

<style lang="css" scoped></style>

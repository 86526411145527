<template>
    <div id="meta-info">
        {{ver}}
    </div>
</template>

<script>
    import { build, version } from '../../package.json';
    export default {
        name: "meta-info",
        computed: {
            ver() {
                
                return `info: {title: "${process.env.VUE_APP_TITLE}", build: "${build}",  version:"${version}"}`
                }
        }
    }
</script>

<style lang="scss" scoped>

</style>
import axios from 'axios';
import authHeader from './_Helpers/auth-header.js';
import appconfig from './_Services/appconfig.js';
import https from 'https';

process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';

var config = appconfig;

// const Http = new XMLHttpRequest();
// const configUrl = '/config.json';
// Http.open('GET', configUrl, false);
// Http.send(null);
// if (Http.status === 200) config = JSON.parse(Http.responseText);

var ApiURL = config.apiEndpoint;
console.log('ApiURL =' + ApiURL);

export const HttpClient = axios.create({
   baseURL: ApiURL,
   headers: {
      'Content-Type': 'application/json',
      Authorization: authHeader(),
      District: config.district || '',
      Secret: process.env.VUE_APP_SECRET || '',
   },
   httpsAgent: new https.Agent({
      rejectUnauthorized: false
   })
});

export const HttpFileClient = axios.create({
   baseURL: ApiURL,
   headers: {
      'Content-Type': 'application/json',
      Authorization: authHeader(),
      District: config.district || '',
      Secret: process.env.VUE_APP_SECRET || '',
   },
   responseType: 'arraybuffer',
});

let config;

(function initConfig() {
   const Http = new XMLHttpRequest();
   const configUrl = process.env.VUE_APP_BASE_URL + 'config.json';
   Http.open('GET', configUrl, false);
   //Http.overrideMimeType('application/json; charset=Windows-1257');
   //Http.setRequestHeader('Content-Type', 'application/json; charset=Windows-1257');
   Http.send(null);
   if (Http.status === 200) {
      config = JSON.parse(Http.responseText);
   }
})();

export default config;

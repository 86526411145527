<template>
  <div>
    <div class="ln-doc-search-title ln-darkblue">Naujienlaiškio prenumerata</div>
    <div v-if="!clasifsLoaded || !newsletterRulesLoaded">Ruošiami prenumeratos duomenys...</div>
    <div v-if="clasifsLoaded && newsletterRulesLoaded">
      <b-button @click="addNewsletterRule" class="mt-3 mb-3" size="md" variant="primary"><font-awesome-icon :icon="['fas','plus']" />  Pridėti naują taisyklę</b-button>
      <div class="ln-border-8-wide p-3">
        <div v-for="rule in newsletterRules" :key="rule.key">
          <newsletter-card
            :model="rule"
            :clasifs="clasifs"
            @deleteRule="deleteNewsletterRule"
            >
          </newsletter-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetDocumentTypesClassificator, GetNewsletterSubscriptionRules, DeleteNewsletterSubscriptionRule } from '@/Assets/repository';
import NewsletterCard from './Components/newsletter-card';

export default {
  name: "newsletter-subscription-page",
  components: { NewsletterCard },
  mounted(){
    GetDocumentTypesClassificator()
      .then((res) => {
        this.clasifs = res.data.documentTypes.filter(i => (i.value > 0));
        this.clasifsLoaded = true;
        // console.log("clasifs: ", this.clasifs);
      })
      .catch((ex) => {
        console.error(ex);
    });
    GetNewsletterSubscriptionRules()
      .then((res) => {
        this.newsletterRules = res.data;
        this.sortRulesByEmail();
        this.enumerateRules();
        this.newsletterRulesLoaded = true;
      })
      .catch((ex) => {
        console.error(ex);
    });
  },
  methods: {
    addNewsletterRule() {
      this.newsletterRules.push({
        id: null,
        key: (this.newsletterRules.length > 0 ? this.newsletterRules[this.newsletterRules.length - 1].key : 0) + 1,
        email: "",
        sendingHour: null,
        sendingWeekDay: null,
        sendingMonthDay: null,
        documentTypes: []
      });
    },
    deleteNewsletterRule(key) {
      let id = this.newsletterRules.find(i => i.key === key).id;
      if (id !== null) {
        DeleteNewsletterSubscriptionRule(id)
          .then(() => this.deleteRuleLocally(key))
          .catch((ex) => {
            console.error(ex);
            this.makeToast('danger', 'Klaida naikinant prenumeratos taisyklę', 5000);
        });
      } else {
        this.deleteRuleLocally(key);
      }
    },
    deleteRuleLocally(key) {
      this.newsletterRules = this.newsletterRules.filter(i => i.key != key);
      this.enumerateRules();
      this.makeToast('success', 'Prenumeratos taisyklė panaikinta sėkmingai', 1500);
    },
    enumerateRules() {
      this.newsletterRules.forEach((e, i) => e.key = i + 1);
    },
    sortRulesByEmail() {
      let sortedList = [...this.newsletterRules];
      sortedList.sort((a, b) => {
          let x = a['email'].toLowerCase();
          let y = b['email'].toLowerCase();
          return new Intl.Collator('lt').compare(x, y);
        });
      this.newsletterRules = sortedList;
    },
    makeToast(variant, title, delay) {
      this.$bvToast.toast(' ', {
      title: title,
      variant: variant,
      toaster: 'b-toaster-bottom-right',
      autoHideDelay: delay,
      solid: true
      });
    }
  },
  data() {
    return {
      clasifs: [],
      newsletterRules: [],
      clasifsLoaded: false,
      newsletterRulesLoaded: false
    };
  },
};
</script>

<style lang="css" scoped></style>

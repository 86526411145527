<template>
   <div style="width: 60%;">
      <b-form-group
         label-align-sm="right"
         content-cols="6"
         :label="'Redakcijos ('+ versionOptions.length + ')'"
         class="ln-subtitle-text ln-darkblue"
      >
         <b-form-select class="ln-border ln-darkblue" v-model="version" :options="versionOptions" @change="versionChange()"></b-form-select>
      </b-form-group>
   </div>
</template>

<script>
export default {
   name: 'document-versions',
   props: {
      versionOptions: Array,
      selectedVersion: Number,
   },
   created() {
      this.version = this.selectedVersion;
   },
   methods: {
      versionChange() {
         this.$emit('versionChange', this.version);
      },
   },
   data() {
      return {
         version: undefined,
      };
   },
};
</script>

<style lang="scss" scoped>
</style>
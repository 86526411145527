<template>
  <div class="ln-border bg-white p-3 mt-2">
    <div v-if="isDocumentFitsInFrame">
      <div v-if="isWindowBig">
        <div
          ref="htmlDocument"
          id="htmlDocument"
          v-if="versionReady"
          class="ln-document-body"
        >
          <div
            v-if="document.htmlDocument != 'Dokumento nepavyko atvaizduoti'"
            v-html="document.htmlDocument"
          ></div>
          <div v-else>
            <div class="mb-2">
              Dokumento nepavyko atvaizduoti puslapyje. Bandykite atsisiųsti.
            </div>
            <btn-document-download v-model="fileGuid"></btn-document-download>
          </div>
        </div>
        <div v-else class="text-info">
          palaukite ruošiamas dokumentas....
          <b-spinner id="spinner" variant="info" small class="ml-2"></b-spinner>
        </div>
      </div>
      <div v-else>
        <btn-document-download v-model="fileGuid"></btn-document-download>
      </div>
    </div>
    <div v-else>
      <div class="mb-2">
        Dokumentas
        <span @click="setDocumentFitsInFrame">netelpa</span> puslapyje. Bandykite
        atsisiųsti.
      </div>
      <btn-document-download v-model="fileGuid"></btn-document-download>
    </div>
  </div>
</template>

<script>
import BtnDocumentDownload from "../../Shared/Components/btn-document-download";
export default {
  name: "document-body",
  components: { BtnDocumentDownload },
  watch: {
    versionReady(val) {
      setTimeout(() => {
        if (val === true) {
          var scrollWidth = this.$refs.htmlDocument.scrollWidth;
          if (scrollWidth > 1300) {
            console.log(
              "Document does not fit in frame, push on word 'netelpa' to render document",
              scrollWidth
            );

            this.isDocumentFitsInFrame = false;
          } else this.isDocumentFitsInFrame = true;
        }
      }, 200);
    },
  },
  props: {
    versionReady: {
      default: false,
      type: Boolean,
    },
    document: Object,
    isWindowBig: {
      default: true,
      type: Boolean,
    },
    fileGuid: String,
  },
  methods: {
    setDocumentFitsInFrame() {
      this.isDocumentFitsInFrame = true;
    },
  },
  computed: {},
  data() {
    return {
      isDocumentFitsInFrame: true,
    };
  },
};
</script>

<style lang="css" scoped>
.doc_link {
  text-decoration: underline !important;
  color: red !important;
}
</style>

<template>
   <div>
      <h2>Pagalbos API</h2>
      <h3>WEB užklausos</h3>
      <ol>
         <li>
            Dokumentų paieška
            <div>/list?padalinys=1&amp;dokRusys={number}&amp;nr={string}&amp;nuo={date}&amp;iki={date}&amp;pavadinimas={string}&amp;tekstas={string}</div>
         </li>

         <li>
            Dokumento gavimas pagal id
            <div>/document/{number}?version={number}</div>
         </li>
         <li>
            Dokumento gavimas pagal vidinį id (sid)
            <div>/document?sid={string}&amp;version={number}</div>
         </li>
         <li>
            Dokumento HTML gavimas pagal vidinį id (sid)
            <div>/document?sid={string}&amp;version={number}</div>
         </li>
          <li>
            Dokumento HTML gavimas pagal registracijos numerį (RegNr)
            <div>/document?regnr={string}&amp;date={date}&amp;dokRusys={number}</div>
         </li>
         <li>
            Dokumento HTML gavimas pagal projekto numerį (ProjNr)
            <div>/document?projnr={string}&amp;date={date}</div>
         </li>
      </ol>
      <h3>API užklausos</h3>
      <ol>
         <li>
            Dokumento HTML gavimas pagal id
            <div>GET /api/Document/{number}?version={number}</div>
         </li>
         <li>
            Dokumento metaduomenų gavimas pagal id
            <div>GET /api/DocumentMeta/{number}?version={number}</div>
         </li>
         <li>
            Dokumento HTML gavimas pagal vidinį id (sid)
            <div>GET /api/Document/GetBySid?sid={string}&amp;version={number}</div>
         </li>
         <li>
            Dokumento bylos gavimas pagal dokumento guid. Guid gaunamas iš metaduomenų.
            <div>GET /api/File/{guid}</div>
         </li>
      </ol>
   </div>
</template>

<script>
export default {
   name: 'help-page',
};
</script>

<style lang="scss" scoped>
</style>
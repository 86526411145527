<template>
  <div>
    <div v-if="configReady" class="ln-blazon ln-subtitle-text noselect" id="ln-district-blazon">      
      <router-link to="/"><img class="ln-logo mr-3 ln-pointer" :src="districtBlazon" /></router-link>
      <span>{{ districtTitle }} dokumentų paieška</span>
      <!-- <font-awesome-icon
        class="float-right ml-3"
        v-if="isAuthenticated && isAuthenticatedAdmin"
        icon="ellipsis-v"
        title="Naudotojų valdymas"
        @click="$router.push('/manageinternaluser')"
      /> -->
      <font-awesome-icon
        class="ln-success float-right ln-pointer"
        v-if="isAuthenticated"
        icon="user"
        title="Prisijungęs vidinis naudotojas"
        @click="$router.push('/logininternaluser')"
      />
      <font-awesome-icon
        class="ln-primary ln-pointer ln-login-icon"
        v-if="showLogin && !isAuthenticated"
        icon="sign-in-alt"
        title="Prisijungti vidiniam naudotojui"
        @click="$router.push('/logininternaluser')"
      />
      <!-- <i class="fas fa-ellipsis-v"></i> -->
    </div>
    <div class="container mt-3">
      <div class="mt-3">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
//import auth from "./_Helpers/auth-header";
import appconfig from "./_Services/appconfig.js";
import { userService } from "./_Services/user-service";

export default {
  name: "district-main",
  created() {},
  computed: {
    districtBlazon() {
      return appconfig.districtBlazonBase64;
    },
    districtTitle() {
      return appconfig.districtTitle;
    },
    configReady() {
      return appconfig != undefined;
    },
    isAuthenticated() {
      //console.log(auth());
      //return auth() != "NoAuth";
      return userService.isAuthorized();
    },
    isAuthenticatedAdmin() {
      //console.log(auth());
      //return auth() != "NoAuth";
      return userService.tokenData().Role == 1;
    },
    showLogin() {
      return process.env.VUE_APP_SHOW_LOGIN === "true";
    }
  },
  data() {
    return {
      clasifs: {},
      ready: false,
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>
<style src="./Assets/css/site.css"></style>
<style src="@vueform/multiselect/themes/default.css"></style>

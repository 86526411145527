<template>
  <div>
    <b-button class="ml-3 ln-bg-primary" type="button" variant="primary" @click="downloadFile"><font-awesome-icon icon="download" class="ln-white mr-2" />Atsisiųsti</b-button>
  </div>
</template>

<script>
import { FileRepo } from "../../_Services/repository";
export default {
  name: "btn-doc-download",
  props: {
    value: String,
  },
  methods: {
    downloadFile() {
      //console.log(this.value);
      FileRepo.get(this.value)
        .then((res) => {
          this.forceFileDownload(res);
        })
        .catch((ex) => {
          console.error(ex);
        });
    },
    forceFileDownload(response) {
      let contentDisposition = response.headers["content-disposition"];
      let filename = "file.unkw";
      if (contentDisposition == null) {
        var mime = require("mime-types");
        let contentType = response.headers["content-type"];
        let extention = mime.extension(contentType);
        filename = extention ? `file.${extention}` : "file.adoc";
      } else {
        filename = contentDisposition
          .split("filename=")[1]
          .split(";")[0]
          .replace('"', "")
          .replace('"', "");
      }
      let blob = new Blob([response.data]);
      if (window.navigator && window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(blob, filename);
      } else {
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      }
    },
  },
};
</script>

<style scoped></style>

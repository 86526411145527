export default class SearchUrlParams {
   static decode(query) {
      return {
         id: query.id,
         klasId: query.klasId,
         nr: query.nr,
         nuo: query.nuo,
         iki: query.iki,
         padalinys: query.padalinys,
         dokRusys: query.dokRusys,
         pavadinimas: query.pavadinimas,
         tekstas: query.tekstas,
         kiekis: query.kiekis,
         puslapis: query.puslapis,
         status: query.status,
         rubrika: query.rubrika,
         leidinys: query.leidinys,
         metai: query.metai,
         leidnr: query.leidnr,
         pubnr: query.pubnr,
         isplestine: query.isplestine,
         textFraze: query.textFraze,
         rengejas: query.rengejas,
      };
   }
   static encode(params) {
      let queryParams = `?`;
      if (params.id != null && params.id != '' && params.id != 2) queryParams += `id=${params.id}&`;
      if (params.klasId != null && params.klasId != '') queryParams += `klasId=${params.klasId}&`;
      if (params.padalinys != null && params.padalinys != '') queryParams += `padalinys=${params.padalinys}&`;
      if (params.dokRusys != null && params.dokRusys != '') queryParams += `dokRusys=${params.dokRusys}&`;
      if (params.nr != null && params.nr != '') queryParams += `nr=${params.nr}&`;
      if (params.nuo != null && params.nuo != '') queryParams += `nuo=${params.nuo}&`;
      if (params.iki != null && params.iki != '') queryParams += `iki=${params.iki}&`;
      if (params.pavadinimas != null && params.pavadinimas != '') queryParams += `pavadinimas=${params.pavadinimas}&`;
      if (params.tekstas != null && params.tekstas != '') queryParams += `tekstas=${params.tekstas}&`;
      if (params.puslapis != null && params.puslapis != '') queryParams += `puslapis=${params.puslapis}&`;
      if (params.kiekis != null && params.kiekis != '') queryParams += `kiekis=${params.kiekis}&`;
      if (params.status != null && params.status != '') queryParams += `status=${params.status}&`;
      if (params.rubrika != null && params.rubrika != '') queryParams += `rubrika=${params.rubrika}&`;
      if (params.leidinys != null && params.leidinys != '') queryParams += `leidinys=${params.leidinys}&`;
      if (params.metai != null && params.metai != '') queryParams += `metai=${params.metai}&`;
      if (params.leidnr != null && params.leidnr != '') queryParams += `leidnr=${params.leidnr}&`;
      if (params.pubnr != null && params.pubnr != '') queryParams += `pubnr=${params.pubnr}&`;
      if (params.isplestine != null && params.isplestine != '') queryParams += `isplestine=${params.isplestine}&`;
      if (params.textFraze != null && params.textFraze != '') queryParams += `textFraze=${params.textFraze}&`;
      if (params.rengejas != null && params.rengejas != '') queryParams += `rengejas=${params.rengejas}&`;
      return queryParams.substring(0, queryParams.length - 1);
   }
   static bind(form, query) {
      let params = this.decode(query);
      if (params.nr != null) form.regNr = params.nr;
      if (params.nuo != null) form.dateFrom = params.nuo;
      if (params.iki != null) form.dateTo = params.iki;
      if (params.padalinys != null) form.organization = params.padalinys;
      if (params.dokRusys != null) form.documentType = params.dokRusys;
      if (params.pavadinimas != null) form.titleText = params.pavadinimas;
      if (params.tekstas != null) form.contentText = params.tekstas;
      if (params.status != null) form.status = params.status;
      if (params.rubrika != null) form.rubrika = params.rubrika;
      if (params.leidinys != null) form.leidinys = params.leidinys;
      if (params.metai != null) form.metai = params.metai;
      if (params.leidnr != null) form.leidnr = params.leidnr;
      if (params.pubnr != null) form.pubnr = params.pubnr;
      if (params.isplestine != null) form.isplestine = params.isplestine;
      if (params.textFraze != null) form.textFraze = params.textFraze;
      if (params.rengejas != null) form.rengejas = params.rengejas;
   }
   static pushToSessionStorage(form) {
      localStorage.setItem('search.nr', form.regNr);
      localStorage.setItem('search.nuo', form.dateFrom);
      localStorage.setItem('search.iki', form.dateTo);
      if (form.organization === 0) form.organization = '';
      localStorage.setItem('search.padalinys', form.organization);
      if (form.documentType === 0) form.documentType = '';
      localStorage.setItem('search.dokRusys', form.documentType);
      localStorage.setItem('search.pavadinimas', form.titleText);
      localStorage.setItem('search.tekstas', form.contentText);
      localStorage.setItem('search.status', form.status);
      localStorage.setItem('search.rubrika', form.rubrika);
      localStorage.setItem('search.leidinys', form.leidinys);
      localStorage.setItem('search.metai', form.metai);
      localStorage.setItem('search.leidnr', form.leidnr);
      localStorage.setItem('search.pubnr', form.pubnr);
      localStorage.setItem('search.isplestine', form.isplestine);
      localStorage.setItem('search.textFraze', form.textFraze);
      localStorage.setItem('search.rengejas', form.rengejas);
   }
   static clearSessionStorage() {
      localStorage.removeItem('search.nr');
      localStorage.removeItem('search.nuo');
      localStorage.removeItem('search.iki');
      localStorage.removeItem('search.padalinys');
      localStorage.removeItem('search.dokRusys');
      localStorage.removeItem('search.pavadinimas');
      localStorage.removeItem('search.tekstas');
      localStorage.removeItem('search.status');
      localStorage.removeItem('search.rubrika');
      localStorage.removeItem('search.leidinys');
      localStorage.removeItem('search.metai');
      localStorage.removeItem('search.leidnr');
      localStorage.removeItem('search.pubnr');
      localStorage.removeItem('search.isplestine');
      localStorage.removeItem('search.textFraze');
      localStorage.removeItem('search.rengejas');
   }
   static getSessionStorage() {
      let params = {};
      params.nr = localStorage.getItem('search.nr');
      params.nuo = localStorage.getItem('search.nuo');
      params.iki = localStorage.getItem('search.iki');
      params.padalinys = localStorage.getItem('search.padalinys');
      params.dokRusys = localStorage.getItem('search.dokRusys');
      params.pavadinimas = localStorage.getItem('search.pavadinimas');
      params.tekstas = localStorage.getItem('search.tekstas');
      params.status = localStorage.getItem('search.status');
      params.rubrika = localStorage.getItem('search.rubrika');
      params.leidinys = localStorage.getItem('search.leidinys');
      params.metai = localStorage.getItem('search.metai');
      params.leidnr = localStorage.getItem('search.leidnr');
      params.pubnr = localStorage.getItem('search.pubnr');
      params.isplestine = localStorage.getItem('search.isplestine');
      params.textFraze = localStorage.getItem('search.textFraze');
      params.rengejas = localStorage.getItem('search.rengejas');
      return params;
   }
}

<template>
   <div>
      <div class="mt-4">
         <span class="mr-4">
            <font-awesome-icon icon="arrow-left" class="ln-primary mr-2" />
            <a
               href="javascript:void(0)"
               class="ln-primary"
               @click="$router.push(`/document/${documentId}`);"
            >Atgal į dokumentą</a>
         </span>
      </div>
   </div>
</template>

<script>
export default {
   name: 'reference-header',
   props: {
      documentId: Number,
   },
};
</script>

<style lang="scss" scoped>
</style>
import { HttpClient } from '../http-client';
//import router from '../router';

export const userService = {
   login,
   logout,
   parseJwt,
   tokenData,
   isAuthorized,
   getDefaultUser,
   checkPassword,
   sendRestorePasswordAndExit,
   sendPasswordRestoreToken
};

function login(username, password) {
   HttpClient.post(
      'Identy/login',
      JSON.stringify({
         UserName: username,
         Password: password,
      })
   )
      .then(res => {
         localStorage.setItem('token', res.data.token);
         localStorage.setItem('tokenExpires', res.data.tokenExpires);

         // setTimeout(function() {
         //    location.href = `${process.env.VUE_APP_BASE_URL}`;
         //    //router.push('/');
         // }, 1000);
      })
      .catch(ex => {
         console.log(ex);
         console.log('auth failed');
      });
}

function logout() {
   localStorage.removeItem('token');
}

function parseJwt(token) {
   var base64Url = token.split('.')[1];
   var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
   var jsonPayload = decodeURIComponent(
      atob(base64)
         .split('')
         .map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
         })
         .join('')
   );

   return JSON.parse(jsonPayload);
}

function tokenData() {
   const token = localStorage.getItem('token');
   const tokenData = userService.parseJwt(token);
   return tokenData;
}

function isAuthorized() {
   const dateNow = Date.now();
   const tokenExpires = Date.parse(localStorage.getItem('tokenExpires'));
   const isTokenExpired = tokenExpires < dateNow;
   const loggedIn = localStorage.getItem('token');
   return loggedIn && !isTokenExpired;
}

function getDefaultUser() {
   return {
      id: null,
      realName: '',
      email: '',
      userName: '',
      password: '',
      active: true,
      isConfirmed: false,
      roles: 0,
      lockoutEnabled: true,
      passwordExpires: '',
   };
}
function checkPassword(inputtxt) {
   var paswd = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
   if (inputtxt.match(paswd)) {
      return true;
   } else {
      return false;
   }
}
function sendRestorePasswordAndExit(username,email){
   HttpClient.post(`account/ResetPasswordLinkRequest`, {
   username: username,
   email: email,
}
)}
function sendPasswordRestoreToken(token){
   HttpClient.post(`account/ResetPassword?token=${token}`, {
   token: token,
})
}
import { render, staticRenderFns } from "./page-not-found.vue?vue&type=template&id=35316a1f&scoped=true&"
import script from "./page-not-found.vue?vue&type=script&lang=js&"
export * from "./page-not-found.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35316a1f",
  null
  
)

export default component.exports
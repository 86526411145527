import Vue from 'vue';
import VueRouter from 'vue-router';
import LoginPage from './Pages/login-page.vue';
import SearchPage from './Pages/search-page.vue';
import ListPage from './Pages/list-page.vue';
import DocumentPage from './Pages/document-page.vue';
import ReferencePage from './Pages/reference-page.vue';
import HelpPage from './Pages/help-page.vue';
import PageNotFound from './Pages/page-not-found.vue';
import MetaInfo from './Pages/meta-info.vue';
import UserManagementPage from './Pages/user-management-page.vue';
import NewsletterSubscriptionPage from './Pages/newsletter-subscription-page.vue';
import ReportProblemPage from './Pages/report-problem-page.vue';
import { userService } from './_Services/user-service';

Vue.use(VueRouter);

const routes = [
   { path: '/', component: SearchPage, meta: { title: 'PAIEŠKA' } },
   { path: '/list', name: 'list', component: ListPage, props: true, meta: { title: 'REZULTATAI' } },
   { path: '/references', component: ReferencePage, props: true, meta: { title: 'REZULTATAI' } },
   { path: '/inoperatives', component: ReferencePage, props: true, meta: { title: 'REZULTATAI' } },
   { path: '/logininternaluser', component: LoginPage, meta: { title: 'LOGIN' } },
   { path: '/manageinternaluser', component: UserManagementPage, meta: { title: 'NAUDOTOJAI' } },
   { path: '/newslettersubscription', component: NewsletterSubscriptionPage, meta: { title: 'NAUJIENLAIŠKIO PRENUMERATA' } },
   { path: '/reportproblem/:id/:version', component: ReportProblemPage, props: true, meta: { title: 'PRANEŠTI APIE PROBLEMĄ' } },
   {
      path: '/document/:id',
      name: 'document',
      component: DocumentPage,
      props: true,
      meta: { title: 'DOKUMENTAS' },
   },
   { path: '/document', component: DocumentPage, props: true, meta: { title: 'DOKUMENTAS' } },
   { path: '/help', component: HelpPage, meta: { title: 'PAGALBA' } },
   { path: '/meta-info', component: MetaInfo, meta: { title: 'INFO' } },
   {
      path: '/Default.aspx',
      redirect: (link) => {
         const _id = getPropertyNoCase(link.query, 'id');
         if (_id == 2) return { path: `/list` };
         if (_id == 3) return { path: `/document/${link.query.DocId}`, query: {} };
      },
   },
   { path: '*', component: PageNotFound, meta: { title: '404' } },
];

const router = new VueRouter({
   base: process.env.VUE_APP_BASE_URL,
   mode: 'history',
   routes: routes,
});

router.beforeEach((to, from, next) => {
   const restrictedPages = ['/manageinternaluser'];
   const restrictedToUsers = ['/newslettersubscription', 'reportproblem'];
   const newsletterEnabled = process.env.VUE_APP_ENABLE_NEWSLETTER === 'true';
   const authRequired = restrictedPages.includes(to.path);
   const loginRequired = restrictedToUsers.includes(to.path);
   const dateNow = Date.now();
   const tokenExpires = Date.parse(localStorage.getItem('tokenExpires'));
   const isTokenExpired = tokenExpires < dateNow;
   const loggedIn = localStorage.getItem('token');
   let role = 0;
   if (loggedIn) {
      const tokenData = userService.tokenData();
      role = tokenData.Role;
      //console.log(tokenData);
   }
   if (authRequired && (!loggedIn || isTokenExpired || role != 1)) {
      return next('/404');
   }
   if (loginRequired && (!loggedIn || isTokenExpired)) {
      return next('/404');
   }
   if (loginRequired && !newsletterEnabled) {
      return next('/404');
   }

   document.title = to.meta.title;
   next();
});
export default router;

const getPropertyNoCase = (obj, prop) => obj[Object.keys(obj).find((key) => key.toLowerCase() === prop.toLowerCase())];

// function parseJwt(token) {
//    var base64Url = token.split('.')[1];
//    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
//    var jsonPayload = decodeURIComponent(
//       atob(base64)
//          .split('')
//          .map(function(c) {
//             return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
//          })
//          .join('')
//    );

//    return JSON.parse(jsonPayload);
// }

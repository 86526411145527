<template>
  <div>
    <b-form @submit="onSubmit" autocomplete="off">
      <div>
        <h3 class="text-danger">Prašome pasikeisti slaptažodį</h3>

        <b-form-group
          label="El. paštas"
          label-for="ctrl_email"
          label-class="ln-text-primary font-weight-bold"
          invalid-feedback="Privalomas laukas"
        >
          <b-form-input
            v-model="email"
            id="ctrl_email"
            type="email"
            :state="email.length != 0"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Esamas slaptažodis"
          label-for="ctrl_pwd"
          label-class="ln-text-primary font-weight-bold"
          invalid-feedback="Privalomas laukas"
        >
          <b-form-input
            v-model="password"
            id="ctrl_pwd"
            type="password"
            autocomplete="new-password"
            :state="password.length != 0"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Naujas slaptažodis"
          label-for="new_pwd"
          label-class="ln-text-primary font-weight-bold"
          invalid-feedback="Neteisingas slaptažodis"
        >
          <b-form-input
            v-model="new_pwd"
            id="new_pwd"
            type="password"
            autocomplete="new-password"
            :state="checkPassword(new_pwd)"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Pakartokite naują slaptažodį"
          label-for="new_pwd"
          label-class="ln-text-primary font-weight-bold"
          invalid-feedback="Slaptažodžiai nesutampa"
        >
          <b-form-input
            v-model="new_pwd_repeat"
            id="new_pwd_repeat"
            type="password"
            autocomplete="new-password"
            :state="new_pwd.length == 0 ? null : new_pwd === new_pwd_repeat"
          ></b-form-input>
        </b-form-group>
      </div>
      <b-button
        :disabled="!formValid"
        type="button"
        @click="onSubmit"
        class="ln-bnt-primary"
      >
        <span v-if="ready">Saugoti</span>
        <span v-if="!ready">Palaukite..</span>
        <b-spinner v-if="!ready" small></b-spinner>
      </b-button>
    </b-form>
    <div v-if="hasError" class="text-danger">{{ errorText }}</div>
  </div>
</template>

<script>
import { userService } from "../../_Services/user-service";
import { HttpClient } from "../../http-client";

export default {
  computed: {
    formValid() {
      if (this.email.length == 0) return false;
      if (this.password.length == 0) return false;
      if (this.new_pwd.length == 0) return false;
      if (!this.checkPassword(this.new_pwd)) return false;
      if (this.new_pwd !== this.new_pwd_repeat) return false;
      return true;
    },
  },
  methods: {
    onSubmit() {
      HttpClient.post(`account/ChangePassword`, {
        id: userService.tokenData().UserId,
        email: this.email,
        password: this.password,
        newPassword: this.new_pwd,
      }).then((res) => {
        console.log(res);
        if (res.data == 0) {
          this.hasError = false;
          this.errorText = "";
          localStorage.removeItem("passwordExpired");
          setTimeout(function () {
            location.href = `${process.env.VUE_APP_BASE_URL}`;
          }, 100);
        } else if (res.data == 1) {
          this.hasError = true;
          this.errorText = "Neteisingas senas slaptažodis";
        } else {
          this.hasError = true;
          this.errorText = "Klaida";
        }
      });
    },
    checkPassword(txt) {
      return userService.checkPassword(txt);
    },
  },
  data() {
    return {
      email: "",
      password: "",
      new_pwd: "",
      new_pwd_repeat: "",
      ready: true,
      hasError: false,
      errorText: false,
    };
  },
};
</script>

<style lang="css" scoped></style>

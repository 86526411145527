export default function authHeader() {
   let token = localStorage.getItem('token');
   if (token && tokenValid()) {
      return 'Bearer ' + token;
   } else {
      return 'NoAuth';
   }
}
function tokenValid() {
   const today = new Date();
   const tokenExpiresDate = new Date(localStorage.getItem('tokenExpires'));
   return tokenExpiresDate > today;
}

<template>
   <div v-if="refs.length > 0" class="ln-border-8 ln-bg-light p-3 mt-4 mb-3">
      <div class="ln-subtitle-text ln-darkblue pl-2 mb-2">
         <span id="appendices_text">Dokumento priedai ({{refsCount}}):</span>
      </div>
      <ol class="ln-subtitle-text ln-darkblue" style="padding-inline-start: 1.6em">
         <li v-for="(ref, index) in refs" :key="index">
            <span>
               <router-link :to="{ name: `document`, params: { id: ref.id }}">
                  <span class="ln-primary ln-subtitle-text" @click="reloadDocument(ref.id)">{{ref.regNr}}</span>
               </router-link>
               <span class="ln-sm-text-bold ln-darkblue ml-1">
                  <font-awesome-icon
                  style="width: 0.5em; margin-right: 0.3em"
                  :class="'ln-sviesoforas-' + ref.statusCode"
                  icon="circle"
                  />
                  {{getStatusName(ref)}}
               </span>
            </span>
            <div class="ln-subtitle-text-thin ln-darkblue mb-2">{{ref.title}}</div>
         </li>
      </ol>
   </div>
</template>

<script>
export default {
   name: 'document-appendices',
   props: {
      refType: String,
      refs: Array,
   },
   methods: {
      reloadDocument(id) {
         this.$emit('reloadDocumentEvent', id);
      },
      getStatusName(item) {
         switch(item.statusCode) {
            case 'success':
               return "GALIOJA";
            case 'warning':
               return "ĮSIGALIOS";
            case 'danger':
               return "NEGALIOJA";
            default:
               return item.status ? item.status.toUpperCase() : "";
         }
      }
   },
   computed: {
      refsCount() {
         if (!this.refs) return 0;
         return this.refs.length;
      },
   },
};
</script>

<style lang="scss" scoped>
</style>
import { HttpFileClient } from '../http-client';

let getFile = function(guid) {
   return new Promise((resolve, reject) => {
      HttpFileClient.get(`file/${guid}`)
         .then(res => {
            resolve(res);
         })
         .catch(ex => {
            reject(ex);
         });
   });
};

export const FileRepo = { get: getFile };

export const normalizeCapitalization = (text) => {
   // console.log("Prieš: ", text);
   let dictionary = {
      "lietuva" : "Lietuva",
      "lietuvos" : "Lietuvos",
      "lietuvai" : "Lietuvai",
      "lietuvą" : "Lietuvą",
      "lietuvoje" : "Lietuvoje",
      "respublika" : "Respublika",
      "respublikos" : "Respublikos",
      "respublikai" : "Respublikai",
      "respubliką" : "Respubliką",
      "respublikoje" : "Respublikoje",
   };
   let lower = text.toLowerCase();
   let result = lower.charAt(0).toUpperCase() + lower.slice(1);
   for (let word in dictionary) {
      let newResult = result.replaceAll(word, dictionary[word]);
      result = newResult;
   }
   // console.log("Po: ", result);
   return result;
}

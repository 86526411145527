<template>
   <div>
      <span class="ln-subtitle-text-thin mr-2">Ryšiai:</span>
      <span>
         <span v-if="getAppendicesLength > 0 && isDocumentView" class="ln-list-ref ln-primary">    
            <span class="mr-2 ln-subtitle-text-thin ln-hover"
               @click="scrollToAppendices">Priedai ({{ getAppendicesLength }})
            </span>      
         </span>
         <span v-for="(o, index) in distinctReferences" :key="index" class="ln-list-ref ln-primary">    
            <span class="mr-2 ln-subtitle-text-thin ln-text-first-letter-capitalize ln-hover"
               @click="navigate(o.typeId)">{{ o.typeName.toLowerCase() }} ({{o.length}})
            </span>      
         </span>
         <span v-if="distinctInoperatives != null" class="ln-list-ref ln-primary">
            <span class="mr-2 ln-subtitle-text-thin ln-hover"
               @click="navigateInoperatives(distinctInoperatives.statusId, distinctInoperatives.typeId)">
               Negaliojantys ({{distinctInoperatives.length}})
            </span>
         </span>
      </span>
   </div>
</template>

<script>
export default {
   name: 'document-reference-link',
   props: {
      refType: String,
      docId: Number,
      klasId: String,
      references: Array,
      inoperatives: Array,
      appendicesLength: Number
   },
   computed: {
      distinctReferences() {
         if (this.references == null) return '';
         let distinct = Array.from(new Set(this.references.map((s) => s.typeId))).map((typeId) => {
            const r = this.references.filter((s) => s.typeId === typeId);
            return {
               typeId: typeId,
               typeName: r[0].typeName,
               length: r.length,
            };
         });
         return distinct;
      },
      distinctInoperatives() {
         if (this.references == null || !this.references.some((o) => o.typeName == 'pakeistas' && o.status == 'negalioja')) return null;
         else {
            const r = this.references.filter((o) => o.typeName == 'pakeistas' && o.status == 'negalioja');
            return {
               statusId: r[0].statusId,
               typeId: r[0].typeId,
               length: r.length,
            };
         }
      },
      getAppendicesLength() {
         return this.appendicesLength;
      },
      isDocumentView() {
         return this.$router.currentRoute.name === "document";
      }
   },
   methods: {
      navigate(klasId) {
         this.$router.push(`/references?id=${this.docId}&klasId=${klasId}`);
         this.$emit('loadList');
      },
      navigateInoperatives(status, klasId) {
         this.$router.push(`/inoperatives?id=${this.docId}&klasId=${klasId}&status=${status}`);
         this.$emit('loadList');
      },
      scrollToAppendices() {
         document.getElementById("appendices_text").scrollIntoView({behavior: "smooth"});
      }
   },
};
</script>

<style lang="css" scoped></style>

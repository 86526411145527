<template>
   <div>
      <search-page @searchEvent="searchEvent" :cacheIsNeeded="true"></search-page>
      <div v-if="loading">
         <b-spinner small variant="primary" label="Loading..." class="mr-1"></b-spinner>Palaukite, ruošiamas sąrašas....
      </div>
      <list-body
         v-else
         :documentList="documentList"
         :currentPage="currentPage"
         :resultPerPage="resultPerPage"
         class="mt-4"
      ></list-body>
      
      <div class="ln-bt-g mt-3 mb-3"></div>

      <paginate
         v-if="ready"
         v-model="currentPage"
         :page-count="pageCount"
         :page-range="2"
         :margin-pages="1"
         :click-handler="changePage"
         :first-last-button="true"
         prev-text="&lsaquo;"
         next-text="&rsaquo;"
         first-button-text="&laquo;"
         last-button-text="&raquo;"
         :page-class="'page-item'"
         :page-link-class="'page-link'"
         :prev-link-class="'page-link'"
         :next-link-class="'page-link'"
         :container-class="'pagination'"
      ></paginate>
   </div>
</template>

<script>
import { GetDocumentList, GetPublicationsList } from '../Assets/repository';
import SearchUrlParams from '../_Helpers/search-url-params';
import SearchPage from './search-page';
import ListBody from './Components/list-body';

export default {
   name: 'list-page',
   components: { SearchPage, ListBody },
   created() {
      if (this.$store.state.searchCacheReady == true) {
         console.log('Search results loaded from cache....');
         this.$router.push('/list' + this.$store.state.searchCache.queryParams);
         this.documentList = this.$store.state.searchCache.value;
         this.currentPage = this.$store.state.searchCache.currentPage;
         this.resultPerPage = this.$store.state.searchCache.resultPerPage;
         this.ready = true;
      } else {
         console.log('Performing new search....');
         this.httpGet();
      }
   },
   methods: {
      changePage(n) {
         this.currentPage = n;
         this.httpGet();
      },
      httpGet() {
         this.loading = true;
         let that = this;
         GetDocumentList(this.queryParams)
            .then(res => {
               that.documentList = res.data;
               that.ready = true;
               that.loading = false;
               that.$store.dispatch('setSearchCache', {
                  currentPage: that.currentPage,
                  resultPerPage: that.resultPerPage,
                  queryParams: that.queryParams,
                  value: that.documentList,
               });

               if (this.isPublicationsDisplayInSearchResultsEnabled) {
                  that.documentList.items.forEach(item => {
                     item.publicationsLoaded = false;
                  });
                  let docIdList = [];
                  for (let doc in that.documentList.items) {
                     docIdList.push(that.documentList.items[doc].id);
                  }
                  if (docIdList.length > 0) {
                     GetPublicationsList(docIdList.join(","))
                        .then(res => {
                           that.documentList.items.forEach(item => {
                              item.publications = res.data.filter(publication => publication.c_id === item.id);
                              item.publicationsLoaded = true;                      
                           });
                           that.documentList = {...that.documentList};
                           that.$store.dispatch('setSearchCache', {
                              currentPage: that.currentPage,
                              resultPerPage: that.resultPerPage,
                              queryParams: that.queryParams,
                              value: that.documentList,
                           });
                        })
                        .catch(ex => {
                           console.error(ex);
                        });
                  }
               }
            })
            .catch(() => {
               this.$router.push('404');
            });
      },
      searchEvent() {
         this.currentPage = 1;
         this.changePage(this.currentPage);
         this.httpGet();
      },
   },
   computed: {
      params() {
         return SearchUrlParams.decode(this.$route.query);
      },
      hasParams() {
         for (var prop in this.params) {
            if (Object.prototype.hasOwnProperty.call(this.params, prop)) {
               if (this.params[prop] != null) return true;
            }
         }
         return false;
      },
      queryParams() {
         let params = this.params;
         params.puslapis = this.currentPage;
         params.kiekis = this.resultPerPage;
         return SearchUrlParams.encode(params);
      },
      pageCount() {
         return Math.ceil(this.documentList.count / this.resultPerPage);
      },
      isPublicationsDisplayInSearchResultsEnabled() {
         return process.env.VUE_APP_SEARCH_DISPLAY_PUBLICATIONS === "true";
      },
   },
   data() {
      return {
         documentList: undefined,
         ready: false,
         loading: false,
         currentPage: 1,
         resultPerPage: 10,
      };
   },
};
</script>

<style lang="css" scoped>
.pointer {
   cursor: pointer;
}
</style>